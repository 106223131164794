.footer{
  background-color: #f2f2f2;
	overflow-x: hidden;
  min-width:800px;
  .foot-top{
    position:relative;
    width:100%;
    height:125px;
    background: #fdbf08;
  }
  .foot-top .bg{
    position:absolute;
    bottom:0px;
    width:100%;
  }
  .foot-top .slogan{
    position:absolute;
    height:50px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  .foot-top .foot-btn-group{
    position:absolute;
    left:70%;
    top:50%;
    transform: translateY(-50%);
    .btn-orange{
      margin-right: 40px;
    }
  }
  .foot-container{
    position:relative;
    height:385px;
    background: #1d1d1d;
    padding:40px 80px 20px 80px;
    color:#e1e1e1;
    font-size:12px;
    letter-spacing: 1.2px;
  }
  .foot-container .bg-position{
    position:relative;
    width:100%;
    max-width:1440px;
    left:50%;
    height:100%;
    transform: translateX(-50%);
    text-align: center;
  }
  .foot-container .foot-content{
    display: inline-block;
    white-space: nowrap;
  }
  .foot-container .foot-left{
    text-align: left;
    display: inline-block;
    >div{
      margin-top:20px;
    }
  }
  .foot-container .foot-left .logo{
    height:42px;
  }
  .foot-container .foot-left .logolink{
    width:40px;
    margin-left:15px;
  }
  .foot-container .foot-left .code-group{
    display: inline-block;
    width:70px;
    text-align: center;
    margin-right:25px;
  }
  .foot-container .foot-left .code-group .codeimg{
    width:100%;
  }
  .foot-container .foot-left .code-group .text{
    margin-top:5px;
  }
  .foot-container .foot-right{
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin-left:200px;
  }
  .foot-container .foot-right .menu-nav{
    display: inline-block;
    vertical-align: top;
    padding:0px 22px;
  }
  .foot-container .foot-right .menu-nav .menu-nav-title{
    font-size:14px;
    color:#e1e1e1;
    margin-bottom:15px;
  }
  .foot-container .foot-right .menu-nav .menu-nav-sub-title{
    border-bottom:1px solid #7e7e7e;
    line-height:20px;
    padding-bottom:2px;
    margin-bottom:2px;
  }
  .foot-container .foot-right .menu-nav .menu-nav-info{
    margin-bottom:5px;
    line-height:20px;
    a{
      color:#e1e1e1;
    } 
    &:hover{
      color:#fb6a00;
      a{
        color:#fb6a00;
      }
    }
  }
  .foot-bottom{
    margin-top:10;
    color: #7F7F7F;
  }
}

@media (max-width:1300px){
  .footer .foot-container .foot-right{
      margin-left:100px;
  }
}

@media (max-width:1200px){
  .footer .foot-container .foot-right{
      margin-left:0px;
  }
  .footer .foot-top .foot-btn-group{
    left:73%;
  }
}

@media (max-width:1080px){
  .footer .foot-container .foot-right{
      margin-left:-40px;
  }
}
@media (max-width:992px){
  .footer .foot-top .foot-btn-group{
    left:78%;
  }
}