.page-secret{
	position: fixed;
	width: 100%;
	height: 100vh;
  background: #fff;
  z-index:100000000000000000;
  p{
    color:#333;
  }
  .secret-box{
    padding-left: 120px;
    padding-right: 120px;
    height: 100%;
    overflow-y: auto;
    .secret-title{
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      font-size:24px;
      margin-top:20px;
    }
    .empty{
      height: 20px;
    }
    .sub-title{
      font-weight: 600;
      font-size: 16px;
    }
  }
}