.navbar-mobile-wrapper{
  width: 100%;
  position: relative;
  .navbar-wrapper{
    position: fixed;
    top:0;
    z-index:1;
    width: 100%;
    height: 0.96rem;
    background: rgba(76, 74, 75, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0.32rem;
    img{
      width: 2rem;
    }
    .icon{
      font-size: 0.32rem;
      color:#fff;
    }
  }
  .drawer{
    width: 100%;
    background: #fff;
    position: fixed;
    top:0.96rem;
    bottom:0;
    z-index:2;
    overflow: hidden;
    touch-action: none;
    transition: all 300ms linear;

  }
  .show{
    transform: translateX(0%);
  }
  .close{
    transform: translateX(100%);
    // transform: translateX(0%);
  }
  .navbar-list-item{
    overflow: hidden;
    position: relative;
    .item-title{
      height: 1.2rem;
  
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom:1px solid #ebeef5;
      // padding:10px;
      padding:0 0.2rem;
      font-size: 0.3rem;
      
    }
  }
  .list-item-content{
    transition: all 500ms linear;
    overflow: hidden;
    max-height: 0;
    padding:0 0.5rem;
    color:#687579;
   
    .content{
      height: 1rem;
      line-height: 1rem;
      font-size: 0.3rem;
    }
    .case{
      padding-left:0.3rem;
    }
    .disabled{
      color:#d8d4d4;
    }
   
  }
}