.product-market-wrapper-mobile{
  background: #fafafa;
  overflow: hidden;
  .main-title{
    font-family: PingFangSC-Semibold;
    color: #443973;
    letter-spacing: 0.036rem;
    font-size:0.32rem;
    font-weight:500;
  }
  .btn{
    padding:0.12rem 0.7rem;
    border-radius: 0.5rem;
    display: inline-block;
    cursor: pointer;
    font-size: 0.28rem;
    letter-spacing: 0.01rem;
  }
  
  .btn-orange{
    border:1px solid #f96119;
    background-color: #f96119;
    color:white;
    border-radius: 0.5rem;
  }
  .btn-orange-hollow{
    background-color:transparent;
    border: 1px solid #FB6A00;
    color:#FB6A00;
  }
  .banner{
    text-align: center;
    position: relative;
    margin-top:0.96rem;
    .banner-bg{
      display: none;
    }
    .banner-bg-mobile{
      width: 100%;
    }
    .info-group{
      background: #fff;
      padding: 10px 10px 30px;
      color:#808080;
      .main-title{
        width: 100%;
        text-align: center;
        font-size: 0.4rem;
        color: #FFFFFF;
        letter-spacing: 0.04rem;
        position:absolute;
        top:0.4rem;
      }
      .info-content{
        width: 100%;
        font-size:0.28rem;
      }
    }
    .banner-pg{
      display: none;
    } 
    .banner-content{
      display: inline-block;
      padding:0.2rem 0;
      .btn-box{
        margin-top:0.4rem;
        .btn-orange{
          margin-right:0.4rem;
        }
      }
    }
  }
  .version-info{
    padding:0.4rem 0.2rem;
    .main-title{
      text-align: center;
      margin-bottom:0.2rem;
    }
    .menu-group{
      display: none;
    }
    .main-png{
      display: block;
      width: 100%;
    }
    
  }
  .center-info{
    background: #fff;
    position: relative;
    padding:1.65rem 0.2rem 0.2rem;
    img{
      width: 100%;
    }
    .info-group{
      color:#808080;
      font-size:0.28rem;
      ul{
        position:relative;
        margin-top:0.5rem;
        list-style: none;
        padding-left: 0.3rem;
        >li{
          position:relative;
          margin:0.18rem 0;
          &:before{
            content:'';
            position:absolute;
            width:0.08rem;
            height:0.08rem;
            background: #FD781D;
            border-radius: 0.1rem;
            left:-0.15rem;
            top:0.15rem;
          }
        }
      }
      .main-title{
        width: 100%;
        text-align: center;
        position: absolute;
        top:0.6rem;
      }
      .sub-title{
        display: none;
      }
    }
  }
  .market-center,.ana-center{
    background: #fafafa;
    .info-container{
      display: flex;
      flex-direction: column;
    }
    .info-right{
      order:1;
    }
  }
  .cloud-info{
    padding: 0.4rem 0.2rem;
    .main-title{
      width: 100%;
      text-align: center;
      margin:0.2rem 0;
    }
    img{
      width: 0.8rem;
      margin-right: 0.2rem;
    }
    .row{
      width: 100%;
      .col{
        width: 100%;
      }
    }
    .cloud-detail{
      width: 100%;
      background: pink;
      padding:0.2rem;
      background: #fff;
      box-shadow: 0 0.08rem 0.3rem 0 rgba(0, 0, 0, .08);
      border-radius: 0.1rem;
      display: flex;
      align-items: center;
      margin-top:0.2rem;
      color:#1e183c;
      font-size: 0.28rem;
      .text{
        font-size:0.26rem;
        color:rgba(0, 0, 0, .4)
      }
    }
  }
  .case-info{
    position:relative;
    background: white;
    text-align: center;
    padding:0 0.4rem 0.5rem;
    .main-title{
      font-size:0.32rem;
      padding:0.5rem 0 0.2rem;
    }
    .banner-bg{
      width: 100%;
    }
    .case-container{
      display: flex;
      justify-content: space-between;

      .case-card{
        position:relative;
        width:2.08rem;
        height:1.3rem;
        display: inline-block;
        display: flex;
        cursor: pointer;
        .case-card-bg{
          position:absolute;
          left:0;
          width:100%;
        }
        .text{
          margin-top: 0.1rem;
        }
        .case-card-icon{
          width: 0.55rem;
          position:absolute;
          top:0.25rem;
          left:50%;
          transform: translateX(-50%);
          -webkit-transition: all 300ms linear;
          -moz-transition: all 300ms linear;
          -ms-transition: all 300ms linear;
          -o-transition: all 300ms linear;
          transition: all 300ms linear;
        }
        .text{
          width:100%;
          position:absolute;
          bottom:0.1rem;
          text-align: center;
          font-family: PingFangSC-Semibold;
          font-size: 0.22rem;
          color: #FFFFFF;
          letter-spacing: 1.6px;
          -webkit-transition: all 300ms linear;
          -moz-transition: all 300ms linear;
          -ms-transition: all 300ms linear;
          -o-transition: all 300ms linear;
          transition: all 300ms linear;
        }
        .case-bottom{
          display: none;
        
        }
      }
    }
  }
}