.home-page-wrapper-mobile{
  width: 100%;
  overflow: hidden;
  background: #fafafa;
  padding-top:0.96rem;

  .main-title{
    font-family: PingFangSC-Semibold;
    color: #443973;
    letter-spacing: 0.036rem;
    font-size:0.29rem;
    font-weight:600;
  }
  .sub-title{
    font-family: PingFangSC-Semibold;
    font-size: 0.3rem;
    color: #454545;
    letter-spacing: 0.016rem;
    font-weight: 500;
  }
  .btn{
    padding:0.12rem 0.7rem;
    border-radius: 0.5rem;
    display: inline-block;
    cursor: pointer;
    font-size: 0.28rem;
    letter-spacing: 0.01rem;
    
  }
  
  .btn-orange{
    border:1px solid #f96119;
    background-color: #f96119;
    color:white;
    border-radius: 0.5rem;
  }
  .btn-orange-hollow{
    background-color:transparent;
    border: 1px solid #FB6A00;
    color:#FB6A00;
  }
  .home-banner{
    width: 100%;
    height: 4.8rem;
    background-image: linear-gradient(-90deg, #fca908, #e9620d);
    position: relative;

    .bg{
      width: 100%;
      position: absolute;
      bottom:0;

    }
    .banner-cont{
      display: flex;
      flex-direction: column;
      align-items: center;
      color:#fff;
      font-size:0.28rem;
      img{
        width: 80%;
        margin:0.8rem 0 0.05rem 0;
      }
    }
    button{
      width: 2.8rem;
      height: 0.6rem;
      background: #fff;
      border-radius: 0.6rem;
      text-align: center;
      border: none;
      color:#ff4800;
      margin:0.7rem auto;
      display: block;

    }
  }
  .tabs{
    display: flex;
    justify-content: center;
    .tab-name{
      width: 30%;
      height: 1.2rem;
      line-height: 1.2rem;
      text-align: center;
      display: block;
    }
    .tabActive{
      border-bottom: 0.04rem solid #fd781d;
    }
   
  }
  .m-t{
    margin-top:0.2rem;
  }
  .btn-box{
    text-align: center;
  }
  .tabs-content{
    display: flex;
    flex-direction: column;
    padding:0 0.3rem;
    .introduce{
      order:1;
      .title{
        margin-top:0.3rem;
        font-size:0.36rem;
        color:#443973;
      }
      .info-content{
        font-size:0.28rem;
      }
      .btn-orange-hollow{
        margin-left:0.4rem;
      }
    }
    .img-bg{
      width: 7rem;
      position: relative;
      .test{
        width: 100%;
        height: 3.6rem;
        background-size:100%;
        background-repeat: no-repeat;
        margin-top:0.45rem;
        display: none;
      }
      .t1-s1-bg{
        background-image: url(../../../assets/images/t1-s1-bg.png);
      }
      .t1-s2-bg{
        background-image: url(../../../assets/images/t1-s2-bg.png);
      
      }
      .t1-s3-bg{
        background-image: url(../../../assets/images/t1-s3-bg.png);
     
      }
      img{
        width: 100%;
        height: 100%;
        display: none;
      }
      .active{
        display: block;
        visibility: visible !important;
      }

      .a-link{
        width: 1rem;
        height: 0.6rem;
        display: inline-block;
        border:0;
        cursor: pointer;
        position:absolute;
      }
      .a1{
        top:0.45rem;
        left:66%;
      }
      .a2{
        top:0.45rem;
        left:32%;
      }
      .a3{
        top:0.45rem;
        left:86%
      }
      .a4{
        width:1.8rem;
        height: 0.75rem;
        top:0.45rem;
        left:19%
      }
      .a5{
        top:1.35rem;
        left:9%;
      }
      .a6{
        top:1.2rem;
        left: 85%;
      }
    }
   

  }
  .module-info{
    background: white;
	  padding:0.3rem 0 0.1rem 0;
    .module-group{
      white-space: nowrap;
	    text-align: center;
      display: flex;
      justify-content: center;
      .group-item{
        width: 1.25rem;
        cursor: pointer;
        &:last-child{
          width: 2rem;
        }
        .item-icon{
          height: 0.7rem;
          
        }
        .item-text{
          font-family: PingFangSC-Semibold;
          font-size: 0.24rem;
          color: #454545;
        }
        &:hover{
          .item-text{
            color: #FD781D;
          }
        }
      }
    }

    .bg-position{
      position:relative;
      width:100%;
      height: 4rem;
      left:50%;
      // height:100%;
      transform: translateX(-50%);
      .module-tip{
        position:relative;
        width:90%;
        margin-left:5%;
        margin-top:0.2rem;
        // height:4rem;
        background: #FFFFFF;
        box-shadow: 0 0.02rem 0.2rem 0 rgba(0,0,0,0.20);
        border-radius: 0.1rem;
        padding:0.3rem 0.25rem;
        .tip-content{
          width:100%;
          display: none;
        
        }
        .tip-content.active{
          transition: all 800ms ease-out;
          display: block;
        }
        .tip-content .f-l{
          float: left;
          .tip-title{
            font-family: PingFangSC-Medium;
            font-size: 0.29rem;
            color: #443973;
          }
          .tip-info{
            // max-width:850px;
            font-family: PingFangSC-Regular;
            font-size: 0.25rem;
            color: #5A5A5A;
          }
        }
        .module-btn{
          padding:0.05rem 0.4rem;
          margin-top:0.2rem;
        }
      }
      
      .module-tip:before,.module-tip:after{
        position: absolute;
        content: '';
        width: 0.14rem;
        height: 0.14rem;
        top: -0.08px;
        left: 50%;
        // margin-left: -7px;
        overflow: hidden;
        pointer-events: none;
        -webkit-transform: rotate(45deg);
        -mz-transform: rotate(45deg);
        transform: rotate(45deg);
      
      }
      .module-tip:before {
        box-shadow: -0.03rem -0.03rem 0.11rem -0.03rem rgba(0, 0, 0, .3)
      }
      
      .module-tip:after {
        top: -0.07rem;
        background: #fff;
      }
      .module-tip.c1:before,.module-tip.c1:after{
        // -webkit-transform: translateX(-390px) rotate(45deg);
        // -moz-transform: translateX(-390px) rotate(45deg);
        // -ms-transform: translateX(-390px) rotate(45deg);
        // -o-transform: translateX(-390px) rotate(45deg);
        transform: translateX(-3rem) rotate(45deg);
      }
      .module-tip.c2:before,.module-tip.c2:after{
        // -webkit-transform: translateX(-208px) rotate(45deg);
        // -moz-transform: translateX(-208px) rotate(45deg);
        // -ms-transform: translateX(-208px) rotate(45deg);
        // -o-transform: translateX(-208px) rotate(45deg);
        transform: translateX(-1.8rem) rotate(45deg);
      }
      .module-tip.c3:before,.module-tip.c3:after{
        // -webkit-transform: translateX(-26px) rotate(45deg);
        // -moz-transform: translateX(-26px) rotate(45deg);
        // -ms-transform: translateX(-26px) rotate(45deg);
        // -o-transform: translateX(-26px) rotate(45deg);
        transform: translateX(-0.4rem) rotate(45deg);
      }
      .module-tip.c4:before,.module-tip.c4:after{
        // -webkit-transform: translateX(158px) rotate(45deg);
        // -moz-transform: translateX(158px) rotate(45deg);
        // -ms-transform: translateX(158px) rotate(45deg);
        // -o-transform: translateX(158px) rotate(45deg);
        transform: translateX(0.85rem) rotate(45deg);
      }
      .module-tip.c5:before,.module-tip.c5:after{
        -webkit-transform: translateX(363px) rotate(45deg);
        -moz-transform: translateX(363px) rotate(45deg);
        -ms-transform: translateX(363px) rotate(45deg);
        -o-transform: translateX(363px) rotate(45deg);
        transform: translateX(2.5rem) rotate(45deg);
      }
    }
  }
  // .bg-position{
  //   position:relative;
  //   width:100%;
  //   max-width:1440px;
  //   left:50%;
  //   // height:100%;
  //   transform: translateX(-50%);
  // }
  .ai-info{
    width:100%;
    padding:0.25rem 0.3rem;
    background: #fff;
    font-size:0.28rem;
    .ai{
      width: 100%;
      margin-bottom:0.3rem;
    }
    .main-title{
      font-size: 0.36rem;
      font-weight: 500;
    }
    .bg-position{
      height: 3.8rem;
    }
    .btn-orange{
      margin-right:0.4rem;
    }
  }
  .solution-info{
    padding:0.3rem;
    font-size:0.28rem;
    .main-title{
      font-size: 0.36rem;
      font-weight: 500;
      text-align: center;
      margin:0.3rem 0;
    }
    .sub-title{
      text-align: center;
      margin:0.3rem 0;
      font-size:0.3rem;
    }
    .que{
      img{
        margin-left:-0.28rem;
      }
    }
  }
  .cycle-info{
    background: #fff;
    padding:0.4rem 0.3rem;
    font-size:0.28rem;
    .bg-position{
      .main-title{
        font-size: 0.36rem;
        font-weight: 500;
        text-align: center;
        margin:0.3rem 0;
      }
      .btn-orange{
        margin-right:0.4rem;
      }
    }
    img{
      width: 100%;
    }
  }

  .value-info{
    display: none;
  }
  .value-info-mobile{
    .main-title{
      text-align: center;
      font-size: 0.36rem;
      font-weight: 500;
      margin-top:0.2rem;
    }
    .info-product-box{
      width: auto;
      white-space: nowrap;
      overflow: scroll;
      padding:0.4rem 0.4rem 0.4rem 0.4rem;
      .info-card{
        width: 3.8rem;
        margin-right:1px;
        display: inline-block;
        white-space: nowrap;
        background: #fff;
        margin-right:0.24rem;
        &:last-child{
          margin-right:0;
        }
        .title{
          text-align: center;
          padding:0.26rem 0;
          img{
            width: 0.4rem;
            margin-right:0.1rem;
          }
        }
        .product-item{
          >div{
            display: flex;
            align-items: center;
            margin:0.2rem 0;
            padding-left:0.4rem;
          }
          img{
            width: 0.8rem;
            margin-right:0.2rem;
          }
          .desc{
            display: flex;
            flex-direction: column;
          }
        }

      }
    }
  }
  .case-info{
    position:relative;
    background: white;
    text-align: center;
    padding:0 0.4rem 0.5rem;
    .main-title{
      font-size:0.36rem;
      padding:0.5rem 0 0.2rem;
      font-weight: 500;
    }
    .info-menu{
      display: none;
    }
    .banner-bg{
      width: 100%;
    }
    .case-content{
      display: flex;
      justify-content: space-between;

      .case-card{
        position:relative;
        width:2.08rem;
        height:1.3rem;
        display: inline-block;
        display: flex;
        cursor: pointer;
        .case-card-bg{
          position:absolute;
          left:0;
          width:100%;
        }
        .text{
          margin-top: 0.1rem;
        }
        .case-card-icon{
          width: 0.55rem;
          position:absolute;
          top:0.25rem;
          left:50%;
          transform: translateX(-50%);
          -webkit-transition: all 300ms linear;
          -moz-transition: all 300ms linear;
          -ms-transition: all 300ms linear;
          -o-transition: all 300ms linear;
          transition: all 300ms linear;
        }
        .text{
          width:100%;
          position:absolute;
          bottom:0.1rem;
          text-align: center;
          font-family: PingFangSC-Semibold;
          font-size: 0.22rem;
          color: #FFFFFF;
          letter-spacing: 1.6px;
          -webkit-transition: all 300ms linear;
          -moz-transition: all 300ms linear;
          -ms-transition: all 300ms linear;
          -o-transition: all 300ms linear;
          transition: all 300ms linear;
        }
        .case-bottom{
          display: none;
        
        }
      }
    }
  }
  //客户
  .custom-info{
    position:relative;
    text-align: center;
    margin-top:0.3rem;
    >.main-title{
      font-size: 0.36rem;
      font-weight: 500;
      padding:0.3rem 0;
    }
   .banner-bg{
    width: 100%;
   }
    .sub-title{
      margin-top:0.3rem
    }
    .cycle-item{
      &:last-child{
        .sub-title{
          margin-top:0.2rem
        }
      }
    }
    .main-title{
      color:#443973;
    }
    .btn-orange{
      margin-right:0.2rem;
    }
    .our-custom{
      margin-top:-0.2rem;
    }
    .bg-position.two{
      margin-bottom:0.8rem;
    }
  }

  .cycle-banner{
    position:relative;
    margin-top:0.2rem;
    width:90%;
    margin-left:5%;
    overflow: hidden;
    >img{
      background: #000;
      display: none;
    }
   
    .banner-bg{
      position:absolute;
      bottom:0px;
      right:0;
      height:2rem;

    }
    .cycle-content{
      transition: all 500ms linear;
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
      .cycle-item{
        display: inline-block;
        width: 100%;
        flex-shrink:0;
        flex-grow:0;
        height: 6.9rem;
        flex-grow:0;
        padding:0.35rem 0.36rem 0 0.4rem;
        background: #fff;
        border-radius: 0.16rem;
        position:relative;
        &:last-child{
          padding-left:0.45rem;
        }
        .info-content{
          color:#454545;
        }
        .info-group{
          white-space: initial;
          text-align: left;
          width:100%;
          .main-title{
            font-size: 0.3rem;
            color: #454545;
          }
          .btn-box{
            text-align: left;
          }
        }
        .lift-info{
          white-space: nowrap;
          height: 1.8rem;
          padding-top:0.45rem;
          text-align: center;
          display: flex;
          justify-content: space-between;
          .info-item{
            display: inline-block;
            height: 100%;
            .text{
              font-family: PingFangSC-Semibold;
              font-size: 0.26rem;
              color: #454545;
            }
            .rate{
              margin-top:0.1rem;
              width:2.65rem;
              height:0.7rem;
              line-height: 0.7rem;
              font-family: PingFangSC-Medium;
              font-size: 0.29rem;
              color: #FFFFFF;
              background: #4162A7;
              border-radius: 0.2rem;
            }
          }
        
        }
        .li{
          bottom:0.2rem;
        }
      }
      .two{
        height: 8.65rem;
        flex-grow:0
      }
    }
    .arrow{
      position:absolute;
      top:50%;
      font-size:0.4rem;
      cursor: pointer;
      &:hover i{
        color:#FD781D;
      }
    }
    .arrow.left{
      right:0.1rem;
    }
    .arrow.right{
      left:0.07rem;
    }
  }
}
