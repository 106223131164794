.footer-mobile{
  .btn{
    padding:0.1rem 0.35rem!important;
    border-radius: 0.5rem;
    display: inline-block;
    cursor: pointer;
    font-size: 0.28rem;
    
  }
  
  .btn-orange{
    border:1px solid #f96119;
    background-color: #f96119;
    color:white;
    border-radius: 0.5rem;
  }
  .btn-orange-hollow{
    background-color:transparent;
    border: 1px solid #FB6A00;
    color:#FB6A00;
  }
  .foot-top{
    position:relative;
    width:100%;
    height:2.7rem;
    background: #fdbf08;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:0.3rem;
  }
  .foot-top .bg{
    position:absolute;
    bottom:0px;
    width:100%;
  }
  .foot-top .slogan{
    width: 6rem;
    margin-bottom:0.4rem;
    // position:absolute;
    // height:0.5rem;
    // top:50%;
    // left:50%;
    // transform: translate(-50%,-50%);
  }
  .foot-top .foot-btn-group{
    // position:absolute;
    // left:70%;
    // top:50%;
    // transform: translateY(-50%);
    .btn-orange{
      margin-right: 40px;
    }
  }
  .foot-container{
    position:relative;
    // height:385px;
    background: #1d1d1d;
    padding:0.4rem 0.25rem 0.2rem 0.25rem;
    color:#e1e1e1;
    font-size:0.22rem;
    letter-spacing: 1.2px;
  }
  .foot-container .bg-position{
    position:relative;
    width:100%;
    // max-width:1440px;
    // left:50%;
    height:100%;
    // transform: translateX(-50%);
    // text-align: center;
  }
  .foot-container .foot-content{
    display: inline-block;
    white-space: nowrap;
  }
  .foot-container .foot-left{
    text-align: left;
    display: inline-block;
    >div{
      margin-top:0.2rem;
      &:nth-child(2){
        margin-left:1.2rem;

      }
    }
  }
  .foot-container .foot-left .logo{
    height:42px;
  }
  .foot-container .foot-left .logolink{
    width:40px;
    margin-left:15px;
  }
  .foot-container .foot-left .code-group{
    display: inline-block;
    width:1.3rem;
    text-align: center;
    margin-right:0.4rem;
    margin-top:0.2rem;
  }
  .foot-container .foot-left .code-group .codeimg{
    width:100%;
  }
  .foot-container .foot-left .code-group .text{
    margin-top:5px;
  }
  .foot-container .foot-right{
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin-left:200px;
  }
  .foot-container .foot-right .menu-nav{
    display: inline-block;
    vertical-align: top;
    padding:0px 22px;
  }
  .foot-container .foot-right .menu-nav .menu-nav-title{
    font-size:14px;
    color:#e1e1e1;
    margin-bottom:15px;
  }
  .foot-container .foot-right .menu-nav .menu-nav-sub-title{
    border-bottom:1px solid #7e7e7e;
    line-height:20px;
    padding-bottom:2px;
    margin-bottom:2px;
  }
  .foot-container .foot-right .menu-nav .menu-nav-info{
    // margin-bottom:5px;
    // line-height:20px;
    a{
      color:#e1e1e1;
    } 
    &:hover{
      color:#fb6a00;
      a{
        color:#fb6a00;
      }
    }
  }
  .foot-nav{
    display: flex;
    justify-content: center;
    font-size: 0.26rem;
    border-bottom:1px solid #3b3b3b;
    padding-bottom:0.3rem;
    margin-bottom:0.2rem;
    >div{
      width: 1.5rem;
      margin-right:1px;
      text-align: center;
    }

  }
  .foot-bottom{
    width: 100%;
    margin-top:0.2rem;
    padding:0.25rem 0 0.3rem;
    color: #7F7F7F;
    border-top:1px solid #3b3b3b;
  }
}

