.case-market{
  background-color: #f2f2f2;
	overflow-x: hidden;
  min-width: 800px;
  .page-banner{
    position:relative;
    width:100%;
    height:250px;
    background: #3C51AB;
    display: flex;
    align-items: center;
    justify-content: center;
    .bg{
      position:absolute;
      height:100%;
    }
    .banner-info{
      position:absolute;
      text-align: center;
      top:140px;
      color:white;
      width:100%;
    }
    div.bg{
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background:url('../../../assets/images/v2/index/bg-yellow.png');
      background-repeat: repeat-x;
      background-size:auto 100%; 
    }
    .bg.l{
      left:0px;
    }
    .bg.r{
      right:0px;
    }
    .main-title{
      margin-top: -45px;
      font-size: 42px;
      color: #FFFFFF;
      letter-spacing: 4.2px;
    }
  }
  
  .tab-content{
    position:relative;
    height:auto;
    width:100%;
    .content-container{
      position:relative;
      width:100%;
      height:745px;
      background: white;
      .info-group{
        position:absolute;
        top:60px;
        left:5%;
        width:40%;
        min-width:467px;
      }
      .info-content{
        margin-top:30px;
      }
      .retail-group{
        min-width: 400px;
        width: 400px;
      }
      .retail-btn{
        margin-right:40px;
      }
      .bg{
        position:absolute;
        right:5%;
        top:50%;
        transform: translateY(-50%);
        height:80%;
      }
      .bg.center{
        right:auto;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        
      }
      .img-a{
        position:absolute;
        top:0;
        border:none;
        display:inline-block
      }
      .a1{
        width: 150px;
        height:100px;
        left:67%;
       
      }
      .a2{
        width: 150px;
        height:100px;
        left:30%;
      }
      .a3{
        width: 150px;
        height:100px;
        left:87%;
      }
    } 
  }
 
  .bg-position{
    position:relative;
    width:100%;
    max-width:1440px;
    left:50%;
    height:100%;
    transform: translateX(-50%);
  }
  .bg-position.small{
      max-width:1180px;
  }

  .case-switcher{
    position: relative;
    height:80px;
    width:100%;
    white-space: nowrap;
    text-align: center;
    .bg-switcher-bar{
      position:absolute;
      top:0px;
      width:420px;
      height:80px;
      border-radius:40px;
      left:50%;
      margin-left:-210px;
      transition:all 500ms ease-out;
    }
    .bg-switcher-bar.color-orange{
      background-color:#ff6800;
    }
    .bg-switcher-bar.color-blue{
      background-color:#4A90E2;
    }
    .case-switcher-item{
      position: relative;
      width:420px;
      height:80px;
      padding:20px 0px;
      text-align: center;
      display: inline-block;
      cursor:pointer;
      .case-switcher-item_inner{
        display: inline-block;
        height:40px;
        >img{
          height: 100%;
          vertical-align: middle;
        }
        .case-switcher-item_inner-text-container{
          vertical-align: middle;
          height:100%;
          display: inline-block;
          text-align: center;
          margin-left:12px;
          letter-spacing: 1px;
          .case-switcher-item_inner-text{
            height:28px;
            font-size:16px;
            font-family:PingFangSC-Semibold;
            font-weight:600;
            color:rgba(69,69,69,1);
            line-height:22px;
          } 
          .case-switcher-item_inner-subtext{
            height:12px;
            font-size:14px;
            font-family:PingFangSC-Semibold;
            font-weight:600;
            color:rgba(69,69,69,1);
            line-height:12px;
          }
        }
      }
    }

    .case-switcher-item.active {
      .case-switcher-item_inner-text-container{
        .case-switcher-item_inner-text,.case-switcher-item_inner-subtext{
          transition: all 200ms linear;
          color:white;
        }
      }
    }  
  }
  .case-switcher.b0 .bg-switcher-bar{
    transform: translateX(-420px);
  }
  .case-switcher.b1 .bg-switcher-bar{
    transform: translateX(0px);
  }
  .case-switcher.b2 .bg-switcher-bar{
    transform: translateX(420px);
  }
  .case-switcher.c1 .bg-switcher-bar{
    transform: translateX(-220px);
  }
  .case-switcher.c2 .bg-switcher-bar{
    transform: translateX(210px);
  }

  .background-info{
    position:relative;
    width:100%;
    padding:50px 0px;
    text-align: center;
    background: white;
    .bg-content{
      display: inline-block;
      text-align: initial;
    }
    .main-title{
      position:relative;
      display: inline-block;
      vertical-align: top;
      margin-right:100px;
    }
    .bg-detail{
      position:relative;
      display: inline-block;
      vertical-align: top;
      width:938px;
      text-align: left;
      .detail-title{
        font-size: 24px;
        color: #443973;
        letter-spacing: 2.4px;
      }
      .detail-info{
        margin-top:20px;
        font-size: 14px;
        color: #454545;
        letter-spacing: 1.4px;
      }
      .detail-more {
        .more-btn{
          cursor: pointer;
          margin-top:5px;
          font-size: 14px;
          color: #FD781D;
          letter-spacing: 1.4px;
        }
      }
      .chart-more{
        margin-top:10px;
        height:0;
        transition: all 500ms linear;
        overflow: hidden;
        text-align:center;
      }
      .chart-more.active.sale{
        height:340px;
      }
      .chart-more.active.bank{
        height:631px;
      }
      .chart-more.active.baoxian{
        height:688px;
      }
      .chart-more.active.haier{
        height:418px;
      }

      .detail-pg{
        margin:10px 0px;
        white-space: nowrap;
        .detail-pb-container{
          display: inline-block;
          width:294px;
          height:208px;
          background: white;
          text-align: center;
          vertical-align: top;
          margin-right:10px;
          .text{
            font-size: 16px;
            color: #454545;
            letter-spacing: 1.6px;
          }
        }
        .detail-pb-container:nth-child(1) .pg{
          margin-top:30px;
          width:170px;
        }
        .detail-pb-container:nth-child(1) .text{
          margin-top:18px;
        }
        .detail-pb-container:nth-child(2) .pg{
          margin-top:10px;
          width:121px;
        }
        .detail-pb-container:nth-child(2) .text{
          margin-top:10px;
        }
        .detail-pb-container:nth-child(3) .pg{
          margin-top:5px;
          width:119px;
        }
        .detail-pb-container:nth-child(3) .text{
          margin-top:10px;
        }
      }
    }
  }

  .case-switcher-content{
    visibility: hidden;
    opacity:0;

  }
  .case-switcher-content.active{
    transition:all 500ms linear;
    visibility:visible;
    opacity:1;
  }
  .solution-info{
    position:relative;
    width:100%;
    background: white;
    text-align: center;
    padding:50px 0px;
    overflow: hidden;
    .info-w{
      display:inline-block;
      white-space:nowrap;
      margin-top:30;
    }
    .w-1{
      display:inline-block;
      width:39%;
      margin-right:30px;
    }
    .w-2{
      display:inline-block;
      width:60%;
    }
    .sol-img{
      max-height:563px;
    }
    .case-text{
      max-width:1023px;
      text-align: left;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #454545;
      line-height: 20px;
      margin-top:25px;
      margin-bottom:22px;
  
    }
  }
  .smart-solution-info{
    position:relative;
    text-align: center;
    height:752px;
    width:100%;
    padding-top:56px;
    .tab-group{
      margin-top:30px;
      white-space: nowrap;
      text-align: center;
      .tab-item{
        display: inline-block;
        width:190px;
        height:45px;
        line-height: 45px;
        font-size: 16px;
        color: #454545;
        letter-spacing: 1.6px;
        cursor:pointer;
      }
      .tab-item:hover{
        color:#FD781D;
      }
      .tab-item.active{
        background: white;
        color:#FD781D;
      }
    }
    .sol-tab-content{
      position:absolute;
      width:100%;
      height:569px;
      background: white;
      .bg-position{
        position:absolute;
        top:0px;
        visibility: hidden;
        opacity:0;
      }
      .bg-position.active{
        -webkit-transition: all 500ms linear;
        -moz-transition: all 500ms linear;
        -ms-transition: all 500ms linear;
        -o-transition: all 500ms linear;
        transition: all 500ms linear;
        visibility: visible;
        opacity:1;
      }
      .q-s-group{
        position:absolute;
        left:80px;
        top:50%;
        transform: translateY(-50%);
        width:31%;
      }
    }
  }
  .q-s-group{
    text-align: left;
    .q-group,.s-group{
      position:relative;
      .q-title,.s-title{
        font-size: 16px;
        letter-spacing: 1.6px;
      }
      .q-text,.s-text{
        margin-top:10px;
        font-size: 14px;
        letter-spacing: 1.4px;
        
      }
      .q-icon,.s-icon{
        position:absolute;
        top:-5px;
        left:-40px;
        width:28px;
        height:28px;
        color:white;
        border-radius:40px;
        text-align: center;
        line-height:28px;
        font-size:20px;
       
      }
      .s-icon:before {
        position: absolute;
        content: '';
        width: 7px;
        height: 12px;
        transform: rotate(45deg);
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        top: 50%;
        right: 10px;
        margin-top:-8px;
        z-index: 2;
      }
    }
   
    .q-group{
      .q-title{
        color: #454545;
      }
      .q-text{
        color: #7F7F7F;
      }
      .q-icon{
        background: #454545;
      }
    }
    .g-1{
      margin-top:100px;
    }
    .s-group {
      .s-title{
        color: #4162A7;
      }
      .s-text{
        color: #4162A7;
      }
      .s-icon{
        background: #FD781D;
      }
      .s-text.big{
        font-size: 16px;
        color: #4162A7;
        letter-spacing: 1.6px;
      }
    }
  }

  .smart-solution-info{
    .sol-tab-content{
      .center-bg{
        position:absolute;
        visibility: hidden;
        opacity:0;
      }
      .center-data-bg{
        right:60px;
        height:395px;
        top:50%;
        transform: translateY(-50%);
        
      }
      .center-segment-bg{
        right:60px;
        height:330px;
        top:50%;
        transform: translateY(-50%);

      }
      .center-auto-bg{
        right:60px;
        height:330px;
        top:50%;
        transform: translateY(-50%);

      }
      .center-ana-bg{
        right:10px;
        height:376px;
        top:50%;
        transform: translateY(-50%);

      }
      .center-ana-bg2{
        right:-70px;
        height:268px;
        top:230px;
      }
      .center-ai-bg{
        right:10px;
        height:363px;
        top:50%;
        transform: translateY(-50%);
      }
      .center-ai-bg2{
        right:-10px;
        height:97px;
        top:210px;
      }
      .center-bg.active{
        -webkit-transition: all 500ms linear 500ms;
        -moz-transition: all 500ms linear 500ms;
        -ms-transition: all 500ms linear 500ms;
        -o-transition: all 500ms linear 500ms;
        transition: all 500ms linear 500ms;
        visibility: visible;
        opacity:1;
      }
      .center-ai-desc{
        position:absolute;
        right:79px;
        font-size: 16px;
        color: #4162A7;
        letter-spacing: 1.6px;
        top:61px;
        .big{
          font-size:32px;
        }
      }
    } 
  } 
  .sol-info{
    position:relative;
    padding:56px 0px 10px 0px;
    text-align: center;
    overflow: hidden;
    .bg-position.small{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left:18px;
    }
    .main-title{
      font-size: 24px;
      color: #443973;
      letter-spacing: 2.4px;
      margin-bottom:20px;
    }
    .q-s-group{
      width:30%;
      min-width: 312px;
    }
    .bg{
      width: 600px;
      margin-left:100px;
    }
    .right-p{
      width:500px;
      margin-left:200px;
      .text{
        font-size: 14px;
        color: #4162A7;
        letter-spacing: 1.4px;
        margin-bottom:5px;
      }
      .bg{
        width:100%;
        right:0px;
        height:auto;
        margin-left:0;
      }

    }
  }
  .sol-info.w-bg{
    background: white;
    padding-bottom:20px;
    .q-s-group{
      top:30px;
    }
    .s-group{
      margin-top: 60px;
    }
  }
  .sol-info.abtest{
    padding-bottom:30px;
    .s-group{
      margin-top:30px;
    }
    .bg2{
      position:absolute;
      height:210px;
      right:-90px;
      bottom:-40px;
    }
  } 
  .sol-info.one{
    .s-group{
      margin-top: 60px;
    }
  }

  .custom-info{
    position:relative;
    background: white;
    padding:30px 0px;
    text-align: center;
    .case-title{
      margin-top:30px;
      margin-bottom:20px;
    }
    .sub-title{
      margin-top:40px;
    }
    .info-group{
      position:absolute;
      top:60px;
      left:5%;
      width:40%;
      min-width:467px;
    }
    .case-card-icon{
      width: 84px;
    }
    .text{
      margin-top: 10px;
    }
  }
  .case-card{
    margin-right:20px;
  }
  .jewelry-case{
    margin-top:100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  }
  .jewelry-case.j0{
    .desc{
      width:50%;
      max-width:631px;
    }
    .img{
      width:312px;
      margin-right:12%;
    }
  }
  .jewelry-case.j1{
    .desc{
      width:443px;
      order:1;
    }
    .img{
      width:312px;
      margin-left:12%;
     
    }

  }
  .jewelry-case.j2{
    .desc{
      width:30%;
      max-width:410px;
    }
    .img{
      width:60%;
      margin-left:12%;
    }
  }
  .jewelry-case.j3{
    .desc{
      max-width:423px;
      width:34%;
      order:1;
      margin-left:12%;
    }
    .img{
      max-width:689px;
      width:60%; 
    }
  }
  .jewelry-case.j4{
    .desc{
      max-width:631px;
      width:49%;
    }
    .img{
      width:380px;
      margin-right:8%;
    }

  }
  .jewelry-case.j5{
    .desc{
      max-width:681px;
      width:50%;
      order:1;
     
    }
    .img{
      width:356px;
      margin-left:7%;
    }
  }
  .jewelry-case.j6{
    .desc{
      max-width:631px;
      width:47%;
     
    }
    .img{
      width:380px;
      margin-right:6%;
    }
  }
  .info-text{
    font-size:14px;
    font-family:PingFangSC-Regular;
    font-weight:400;
    color:rgba(69,69,69,1);
    line-height:20px;
    margin-bottom:20px;
    letter-spacing:1px;
  }
  .sub-main-title{
    font-size:18px;
    font-family:PingFangSC-Semibold;
    font-weight:600;
    color:rgba(68,57,115,1);
    line-height:25px;
    margin-bottom:20px;
  }
  .img-text{
    font-size:18px;
    font-family:PingFangSC-Medium;
    font-weight:500;
    color:rgba(68,57,115,1);
    line-height:25px;
    text-align: center;
  }

  .jewelry-wrapper{
    .jewelry-desc{
      width:82%;
      margin:0 auto;
      position:relative;
      >div{
        position:relative;
        width:100%;
        &:first-child{
          display: flex;
          align-items: center;
          padding-top:65px;
          .desc{
            order:1;
            margin-left:9%;
            width: 56.5%;
          }
        }
        .desc{
          right:122px;
          top:69px;
          max-width:682px;
         
          .main-title{
            font-size:26px;
            margin-bottom:15px
          }
        }
        .desc-2{
          left:178px;
          top:95px;
          width:312px;
         
          .img-text{
            position:relative;
            top:-19px;
            span{
              color:#ff6800
            }
          }
        }
      }

    }
    .more-case{
      text-align: center;
      padding:60px 0;
      img{
        width:675px
      }
    }
  }
  .more-case-food{
    text-align:center;
    margin-top:30px;
    img{
      width: 835px;
      width: 65%;
    }
  }

  .food-fast-sell{
    >div{
      width: 100%;
      height: 3709px;
      position: relative;
    }
    .food-fast-desc{
      display: flex;
      justify-content: center;
      padding-top:40px;
      .desc-item{
        height: 328px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-right:120px;
        &:nth-child(1){
          width:230px;
        }
        &:nth-child(2){
          img{
            width: 175px;
            margin-bottom:30px;
          }
        }
        &:nth-child(3){
          width:180px ;
          margin-right:0;
          .img-text{
            margin-bottom: 25px;
            margin-top:25px;
          }
        }
        img{
          width: 100%;
          height: auto;
        }
        .img-text{
          span{
            color:#ff6800;
          }
        }
      }
    }
    .industry{
      display: flex;
      justify-content: center;
      margin-top:120px;
      .main-title{
        text-align: center;
        margin-bottom:20px;
        font-size:26px;
      }
      .background{
        width: 39%;
        max-width: 514px;
        margin-right: 90px;
      }
      .painSpot{
        max-width:462px;
        width:36%;
      }
    }
    .b2c-case{
      width:100%;
      height:317px;
      margin-top:120px;
      >div{
        max-width:1246px;
        width:86%;
        margin: 0 auto;
        .main-title{
          text-align: center;
          margin-bottom:20px;
          font-size:26px;
        }
      }
    }
  }
  .fast-sell-module{
    width:86%;
    padding-top:110px;
    display: flex;
    align-items: center;
    margin:0 auto;
  }
  .left-module{
    max-width:566px;
    width:45%;
    margin-right:150px;
  }
  .right-module{
    width: 286px;
  }
  .sell-module2{
    justify-content:flex-end;
    padding-right:80px;
    .left{
      width:306px;
      margin-right:150px;
    }
    .right{
      max-width:568px;
      width:45%;
    }
  }
  .sell-module3{
    margin-top:120px;
    padding:0;
    >div{
      width: 554px;
    }
  }
  .sell-module4{
    padding-top:70px;
    justify-content: space-between;
    .left{
      width:566px;
      >div{
        &:nth-child(2){
          width: 385px;
        }
      }
    }
    .right{
      width:286px;
      margin-right:15%;
    }
  }
  .info-text-li{
    position: relative;
    font-size:14px;
    font-family:PingFangSC-Semibold;
    font-weight:600;
    color:rgba(69,69,69,1);
    line-height:41px;
  }
  .info-text-li.small{
    line-height:24px;
  }
  .info-text-li.regular{
    font-family:PingFangSC-Regular;
    font-weight:400;
  }
  .info-text-li.nopoint::before{
      display: none;
  }
  .info-text-li::before{
      content:'';
      position:absolute;
      border-radius:20px;
      width:10px;
      height:10px;
      background: #ff6800;
      top:13px;
      left:-16px;
  }
  .info-text-li.blue::before{
      background: #4A90E2;
  }
  .info-text-li.small::before{
      top:7px;
  }
  .smart-sol-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    .smart-sol-group{
      width:1126px;
      .smart-sol-description{
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: #454545;
        text-align: justify;
        line-height: 26px;
        font-weight: 600; 
        text-indent:2em;
        margin-top: 40px;
      }
      .smart-sol-title{
        font-family: PingFangSC-Semibold;
        font-size: 18px;
        color: #454545;
        line-height: 26px;
        font-weight: 600;
        margin-top: 40px;
      }
      .smart-sol-title-orange{
        font-family: PingFangSC-Medium;
        font-size: 20px;
        color: #F46225;
        font-weight: 500;
        margin-top: 40px;
      }
      .smart-sol-text{
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #7F7F7F;
        text-align: justify;
        line-height: 24px;
        font-weight: 400;
        margin-top: 10px;
        .orange{
          font-family: PingFang-SC-ExtraLight;
          font-size: 14px;
          color: #F46225;
          text-align: justify;
          line-height: 24px;
          font-weight: 400;
        }
      }

    }
  }
  .pro-value-info{
    position:relative;
    height:380px;
    text-align: center;
    padding:50px 0px;
  }

  .pg-group{
    margin-top:20px;
    display: inline-block;
    white-space: nowrap;
    .pg-item {
      display: inline-block;
      background: #FFFFFF;
      border-radius: 10px;
      width:294px;
      height:180px;
      position: relative;
      margin-right:20px;
      padding:26px 0;
      &:last-child{
        margin-right:0;
      }
      img{
        width:90px;
      }
      .text{
        margin-top:15px;
        font-size: 16px;
        color: #454545;
        letter-spacing: 1.6px;
      }
    }
  }
  .smart-solution-text{
    padding:0px 112px;
    font-size: 14px;
    color: #454545;
    letter-spacing: 1.4px;
    text-align: left;
    margin-bottom:20PX;
  }
  .img-group{
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    .img-container{
      display: inline-block;
      vertical-align: top;
      .i-title{
        font-size: 16px;
        color: #454545;
        letter-spacing: 1.6px;
      }
      .text{
        margin-top:10px;
        text-align: left;
        white-space: initial;
        font-size: 14px;
        color: #454545;
        letter-spacing: 1.4px;
      }
      .text.after{
        font-size: 14px;
        color: #443973;
        letter-spacing: 1.4px;
      }
    }
  }
  .content-container{
    .case-bank-bg{
      a{
        position: absolute;
        width: 261px;
        height: 163px;
        top:40px;
        left:20%;
        border:0;
        display: inline-block;
      }
      .info-group{
        min-width: 420px;
        width: 420px;
        >div{
          &:last-child{
            margin-top:20px;
            .btn-orange{
              margin-right: 40px;
            }
          }
        }
      }
      
    }
    .insurance-bg{
      a{
        width: 131px;
        height: 63px;
        position: absolute;
        display: inline-block;
        border:0;
      }
      .a-pingan{
        top:40%;
        left:10%;
      }
      .a-picc{
        top:35%;
        left:87%;
      }
      .info-group{
        min-width: 420px;
        width: 420px;
        >div{
          &:last-child{
            margin-top:20px;
            .btn-orange{
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
  .bank-background{
    .bg-detail .detail-pg .detail-pb-container{
      .pg.p1{
        margin-top:30px;
        width: 152px;
      }
      .pg.p2{
        margin-top: 33px;
        width: 140px;
      }
      .pg.p3{
        margin-top: 25px;
      }
    }
   
  }

  .bank-solution,.insurance-solution{
    .bg-position{
      padding-left:15px;
      >div{
        display: inline-block;
        white-space: nowrap;
        margin-top:30px;
        display: flex;
        justify-content: center;
        .left{
          width: 387px;
          margin-right:60px;
          white-space: initial;
        }
        .right{
          img{
            height: 100%;
            max-height: 563px;
          }
        }
      }
    }
  }
  .bank-smart{
    height: auto;
    .bg-position.small{
      >img{
        width: 1126px;
        margin-top:20px;
      }
    }
  }
  .bank-custom,.insurance-custom{
    >img{
      width: 821px;
      margin:10px 0 60px 0;
    }
    .case-group{
      margin-top:20px;
    }
  }
  .insurance-custom >img{
    width: 481px;
  }
  .insurance-smart{
    .img-group{
      margin-top:50px;
      .img-container{
        width: 333px;
        >img{
          width: 333px;
          margin-top:39px;
        }
        &:last-child{
          width: 400px;
          margin-left:120px;
          >img{
            width: 189px;
            margin:0;
          }
        }
      }
    }
  }

  
  @media (max-width:1360px){
    .tab-content .content-container .bg{
        height:70%;
    }
    .background-info .bg-detail{
        width:905px;
    }
    .solution-info .sol-img{
        max-height:463px;
    }
    .case-switcher .bg-switcher-bar{
        width:390px;
        margin-left:-196px;
    }
    .case-switcher.b0 .bg-switcher-bar{
        transform: translateX(-390px);
    }
    .case-switcher.b1 .bg-switcher-bar{
        transform: translateX(0px);
    }
    .case-switcher.b2 .bg-switcher-bar{
        transform: translateX(390px);
    }
    .case-switcher.c1 .bg-switcher-bar{
        transform: translateX(-180px);
    }
    .case-switcher .case-switcher-item{
        width:390px;
    }
  }
  @media (max-width:1200px) {
    .tab-content{
      .content-container{
        height: 655px;
        .retail-group{
          min-width: 350px;
          width: 350px;
        }
        .bg{
          right:2%;
          top:52%;
          height: 68%;
        }
        .case-bank-bg,.insurance-bg{
          .bg{
            height: 65%;
          }
        }
      }
    }
    .case-switcher .bg-switcher-bar{
      width:320px;
      margin-left:-160px;
    }
    .case-switcher .case-switcher-item{
      width:320px;
    }
    .case-switcher.b0 .bg-switcher-bar{
      transform: translateX(-320px);
    }
    .case-switcher.b2 .bg-switcher-bar{
        transform: translateX(320px);
    }
    .background-info{
      .main-title{
        margin-right:40px;
      }
      .bg-detail{
        width: 750px;
        .detail-pg{
          .detail-pb-container{
            width: 260px;
          }
        }
      }
    }
    .solution-info .sol-img{
      max-height:450px;
    }
    .smart-solution-info{
      .sol-tab-content{
        .q-s-group{
          width: 27.5%;
        }
        .center-ana-bg{
          height: 340px;
          right:36px;
        }
        .center-ana-bg2{
          height: 250px;
          right:-40px;
        }
      }
    }
    .sol-info.abtest{
      .bg{
        width: 550px;
        margin-left:30px;
      }
      .bg2{
        height: 180px;
        right:-70px;
      }
    }
    .sol-info.w-bg{
      .right-p{
        width:400px;
        margin-left:160px;
      }
    }
    .sol-info.one{
      .bg{
        width: 540px;
        margin-left:30px;
      }
    }
    .jewelry-wrapper{
      .jewelry-desc{
        width:86%;
      }
    }
    .bank-solution,.insurance-solution{
      .bg-position{
        padding-left:30px;
        >div .right img{
          max-height: 500px;
        }
      }
    }
    .bank-smart{
      .bg-position.small{
        >img{
          width: 90%;
        }
      }
      .smart-sol-wrapper .smart-sol-group{
        width: 90%;
      }
    }
  }
  @media (max-width:992px){
    .tab-content{
      .content-container{
        height: 560px;
        .info-group{
          left:3%;
        }
        .retail-group{
          min-width: 330px;
          width: 330px;
        }
        .bg{
          right:2%;
          top:56%;
          height: 62%;
        }
        .case-bank-bg,.insurance-bg{
          .info-group{
            width: 390px;
            min-width: 390px;
            >div{
              &:last-child{
                .btn-orange{
                  margin-right: 18px;
                }
              }
            }
          }
          .bg{
            height: 60%;
          }
        }
      } 
    }
    .case-switcher .bg-switcher-bar{
      width:250px;
      margin-left:-125px;
    }
    .case-switcher .case-switcher-item{
      width:250px;
    }
    .case-switcher.b0 .bg-switcher-bar{
      transform: translateX(-250px);
    }
    .case-switcher.b2 .bg-switcher-bar{
        transform: translateX(250px);
    }
    .background-info{
      .bg-content{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .bg-detail{
        .detail-title{
          text-align: center;
        }
      }
    }
    .solution-info .sol-img{
      max-height:360px;
    }
    .smart-solution-info{
      .tab-group{
        .tab-item{
          width:160px;
        }
      }
      .sol-tab-content{
        .q-s-group{
          width: 27%;
        }
        .center-ana-bg{
          height: 270px;
          right:25px;
        }
        .center-ana-bg2{
          height: 210px;
          right:-20px;
        }
        .center-ai-bg{
          height: 300px;
        }
        .center-ai-desc{
          right:9px;
        }
        .center-segment-bg{
          right:4.5%;
          height: 290px;
        }
      }
    }

    .sol-info.abtest{
      .main-title{
        font-size: 24px;
      }
      .q-s-group{
        min-width: 255px;
        .q-group,.s-group{
          .q-title,.s-title{
            font-size:15px;
          }
          .q-text,.s-text{
            font: size 13.5px;
          }
          .s-text.big{
            font-size: 15px;
          }
        }
      }
      .bg{
        width: 440px;
        margin-left:20px;
      }
      .bg2{
        height: 150px;
        right:-60px;
      }
    }
    .sol-info.w-bg{
      .right-p{
        width:385px;
        margin-left:40px;
      }
    }
    .sol-info.one{
      .bg{
        width: 390px;
        margin-left:40px;
      }
    }
    .custom-info{
      .case-container{
        left:50%;
        .case-card{
          width: 235px;
          height: 150px;
          .case-card-icon{
            width: 60px;
          }
          .text{
            bottom:20px;
          }
        }
      }
    }
    .food-fast-sell{
      .food-fast-desc{
        .desc-item{
          margin-right:80px;
        }
      }
    }
    .bank-solution,.insurance-solution{
      .bg-position{
        padding-left:45px;
        >div{
          .left{
            margin-right:30px;
          }
          .right img{
            max-height: 430px;
          }
        } 
      }
    }
    
    .pg-group .pg-item{
      width: 250px;
    }
    .insurance-smart  .img-group .img-container{
      &:last-child{
        margin-left:42px;
      }
    }
  }
}
