.about-us-wrapper{
  // background-color: #f2f2f2;
	// overflow-x: hidden;
  min-width: 800px;
  .bg-position{
    position:relative;
    width:100%;
    max-width:1440px;
    left:50%;
    height:100%;
    transform: translateX(-50%);
  }
  .banner{
    position:relative;
    height:380px;
    width:100%;
    overflow: hidden;
    background: #000;
    background-image: url(../../../assets/images/v2/about/banner-bg.jpeg) ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    .bg{
      position:absolute;
      width:100%;
      bottom:-108%;
    }
    .mask-about{
      position:absolute;
      left:0px;
      right:0px;
      top:0px;
      bottom:0px;
      width:100%;
      height:100%;
      background: rgba(65, 98, 167, 0.50);
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .main-title{
        font-size: 42px;
        color: #FFFFFF;
        letter-spacing: 4.2px;
      }
      .banner-info{
        font-family: PingFangSC-Regular;
        font-size: 20px;
        color: #FFFFFF;
        font-weight: 400;
        margin-top: 26px;
      }
    }
  
  
  }
  .tab-group{
    position:relative;
    height:70px;
    white-space: nowrap;
    text-align: center;
    .tab-item{
      display: inline-block;
      width:248px;
      height:70px;
      border-bottom:2px solid transparent;
      line-height: 68px;
      font-family: PingFangSC-Semibold;
      font-size: 16px;
      color: #454545;
      letter-spacing: 1.6px;
      cursor: pointer;
      &:hover{
        color:#FD781D;
      }
    }
    .tab-item.active{
      background: white;
      border-bottom:2px solid #FD781D;
      color:#FD781D;
    }
  }
  .tab-content{
    position:relative;
    .company{
      background: white;
      padding:70px 0px;
      text-align: center;
      .com-title{
        font-family: PingFangSC-Medium;
        font-size: 24px;
        color: #454545;
        line-height: 26px;
        font-weight: 500;
        .logo{
          height:30px;
        }
        span{
          vertical-align: sub;
        }
      }
      .com-info{
        position: relative;
        left:50%;
        transform: translateX(-50%);
        width:60%;
        max-width:740px;
        text-align: left;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #454545;
        letter-spacing: 1.4px;
        line-height: 25px;
        margin-top:22px;
      }
      .margin30{
        margin-top:30px;
      }
      .btn-box{
        margin-top:30px;
        .btn-orange{
          margin-right:50px;
        }
      }
      .com-honor-head{
        white-space: nowrap;
        text-align: left;
        width:80%;
        margin-top:30px;
        margin-left:10%;
        vertical-align: middle;
        .icon{
          display: inline-block;
          vertical-align: middle;
          height:30px;
        }
        .text{
          display: inline-block;
          vertical-align: middle;
          font-family: PingFangSC-Medium;
          font-size: 24px;
          color: #454545;
          letter-spacing: 2.4px;
        }
        hr{
          position:relative;
          top:17px;
          display: inline-block;
          width:90%;
          margin:20px 0 20px 0;
          border: 0;
          border-top: 1px solid #eee;
        }
      
      }
      .com-honor-list{
        margin-top:20px;
        height:700px;
        padding-left:30px;
        width:80%;
        margin-left:10%;
        text-align: left;
        overflow: scroll;
        .list-item{
          .honor-time{
            display: inline-block;
            width:110px;
            font-family: PingFangSC-Semibold;
            font-size: 16px;
            color: #454545;
            text-align: justify;
            font-weight: 600;
            height: 40px;
            line-height: 40px;
          }
          .honor-info{
            vertical-align: top;
            display: inline-block;
            width:80%;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #454545;
            letter-spacing: 1.4px;
            line-height: 40px;
          }
          .fg{
            width:97px;
            height:3px;
            background:#ff8700;
            border-radius:8px;
            margin:10px 0px;
          }
        
        } 
      }
    }
    .team{
      background: white;
      padding-bottom:30px;
      .team-map{
        position:absolute;
        right:0px;
        height:237px;
        top:30px;
      }
      .team-head{
        transition: all 450ms linear;
      }
      .team-head.open{
        .info-group{
          .info-content{
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #454545;
            text-align: justify;
            line-height: 24px;
            font-weight: 400;
            .padding30{
              padding-top: 30px;
            }
          }
          >div{
            &:last-child{
              margin-top:20px;
              .btn-orange{
                margin-right:50px;
              }
            }
          }
        }
      }
      .info-group{
        width:40%;
        margin-left:200px;
        padding:40px 0 20px;
      }
      .team-member{
        .member-group {
          padding-left:200px;
          .head-title{
            font-family: PingFangSC-Medium;
            font-size: 24px;
            color: #454545;
            letter-spacing: 2.4px;
          }
          .main-member{
            .headimg{
              width:321px;
              vertical-align: top; 
              margin-bottom:30px;
              margin-right:50px;
            }
            .member-info{
              position: relative;
              display: inline-block;
              width:730px;
              vertical-align: top;
              .title{
                font-family: PingFangSC-Medium;
                font-size: 24px;
                color: #454545;
              }  
              .desc{
                margin-top: 45px;
                font-size: 14px;
                color: #454545;
                text-align: justify;
                line-height: 24px;
                font-weight: 400;
                .desc-item{
                  font-family: PingFangSC-Regular;
                  font-size: 14px;
                  color: #454545;
                  text-align: justify;
                  line-height: 24px;
                  font-weight: 400;
                  padding-top: 25px;
                }
              }
            }
          }
          .member-content{
            width:300px;
            height:450px;
            .headimg{
              width:210px;
            }
            .title{
              font-family: PingFangSC-Semibold;
              font-size: 24px;
              color: #454545;
              line-height: 34px;
              font-weight: 600;
            }
            .desc{
              margin-top:15px;
              font-family: PingFangSC-Regular;
              font-size: 14px;
              color: #7F7F7F;
              letter-spacing: 1.4px;
            }
          }
        }
      }
    }
    .custom{
      background: white;
      padding:50px 0px;
      text-align: center;
      .custom-title{
        margin-top:50px;
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #454545;
        font-weight: 600;
      }
      .custom-img{
        width:1080px;
        margin-top: 20px;
      }
    
    
    }
    .join{
      background: white;
      padding:30px 0px;
      .join-head{
        height:200px;
        width:100%;
        text-align: center;
        .head-left{
          text-align: left;
          padding-left:50px;
          .head-text{
            font-family: PingFangSC-Semibold;
            font-size: 16px;
            color: #454545;
            letter-spacing: 1.6px;
            font-weight: 600;
          }
          .btn-box{
            margin-top:20px;
            .btn-orange{
              margin-right:40px;
            }
          }
          
        }

        .head-left,.head-right{
          display: inline-block;
          width:45%;
        }
      }
      .row{
        width: 90%;
        text-align: center;
        padding-top: 30px;
        margin:0 auto;
      }
      .goodness-card{
        position:relative;
        width:290px;
        height:213px;
        background: #FFFFFF;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.10);
        border-radius: 8px;
        padding:14px 40px;
        margin-bottom:20px;
        .c-top{
          position:relative;
          height:60px;
          .c-title{
            position:absolute;
            top:50%;
            transform: translateY(-50%);
            font-family: PingFangSC-Semibold;
            font-size: 16px;
            color: #454545;
            letter-spacing: 1.6px;
          }
          .c-logo{
            position:absolute;
            right:0px;
            width:60px;
        } 
        }
        .c-detail{
          margin-top:18px;
          font-family: PingFangSC-Regular;
          font-size: 13px;
          color: #454545;
          letter-spacing: 1.3px;
          text-align: left;
        }
      }
      .job-info{
        width:90%;
        text-align: center;
        padding-top:50px;
        margin: 0 auto;
        .main-title{
          font-family: PingFangSC-Medium;
          font-size: 24px;
          color: #454545;
          font-weight: 500;
          text-align: center;
        }
        .margin80{
          margin-top: 80px;
        }
        .location-group{
          margin-top:20px;
          .location-item{
            display: inline-block;
            width: 19%;
            text-align: center;
            .text{
              margin-top:16px;
              font-family: PingFangSC-Regular;
              font-size: 14px;
              color: #545454;
              letter-spacing: 0;
              font-weight: 400;
            }
            .png{
              height:80px;
            }
          
          }
          .location-item.active{
            .text{
              color:#FD781D;
            }
          }
        }
        .job-detail{
          margin-top:20px;
          .job-detail-head{
            white-space: nowrap;
            padding-left:230px;
            text-align: left;
            border-bottom:1px solid #e1e1e1;
            .head-item{
              display: inline-block;
              width:247px;
              height:42px;
              font-family: PingFangSC-Semibold;
              font-size: 16px;
              color: #454545;
              letter-spacing: 1.6px;
              line-height: 42px;
              text-align: center;
              cursor: pointer;
            }
            .head-item.active{
              color:white;
              background: #ff6800;
            }
          }
          .job-detail-info{
            text-align: left;
            width:100%;
            white-space: nowrap;
            min-height:540px;
            .job-detail-name-list{
              display: inline-block;
              width:208px;
              .job-detail-name-list-item{
                text-align: right;
                padding:26px 0px;
                border-bottom:1px solid #e1e1e1;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #454545;
                letter-spacing: 1.4px;
                cursor: pointer;
                &:last-child{
                  border-bottom:0px;
                }
                img{
                  width: 15px;
                }
              }
              .job-detail-name-list-item.active{
                color:#FD781D;
                .iconfont{
                  visibility: visible;
                }
              }
          
            }
            .job-detail-content{
              vertical-align: top;
              display: inline-block;
              padding-left:24px;
              padding-top:24px;
              width:910px;
              white-space: initial;
              .sub-title{
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #454545;
                letter-spacing: 1.4px;
                line-height:40px;
                margin-top:40px;
                &:first-child{
                  margin-top:0;
                }
              }
              .text{
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #7F7F7F;
                letter-spacing: 1.4px;
                line-height: 24px;
              }
              .hrInfo{
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #7F7F7F;
                line-height: 24px;
                font-weight: 400;
                margin-top: 32px;
              }
              .title{
                display: none;
              }
            
            }
          }
          .ant-collapse{
            display: none;
          }
        }
      }
    }
  }
  .concat{
    background: white;
    padding:60px 0px;
    .bg-position{
      >div{
        width: 90%;
        margin-left:30%;
      }
    }
    .form-content{
      display: inline-block;
      width:540px;
      min-height:358px;
      background: #F8F0EC;
      padding:15px;
      input,textarea{
        border:0px;
        margin-bottom:10px;
        border-radius:8px;
      }
    }
    .service-content{
      vertical-align: top;
      display: inline-block;
      margin-left:70px;
      .text{
        margin-bottom:20px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #454545;
        letter-spacing: 1.4px;
      }
      .png-group{
        margin-top:42px;
        text-align: center;
        width:170px;
        img{
          width:170px;
        }
        .text{
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #454545;
          letter-spacing: 1.4px;
        }
      }
    }
  
  }

  
  
  @media (max-width:1360px){
    
    .tab-group .tab-item{
      width:220px;
    }
    .tab-content{
      .join{
        .goodness-card{
          width: 260px;
        }
      }
    }
  }
  @media (max-width:1200px){
    
    .tab-group .tab-item{
      width:200px;
    }
    .tab-content{
      .join{
        .row{
          width: 95%;
        }
        .goodness-card{
          width: 225px;
          padding:14px 32px;
        }
        .job-info{
          width: 95%;
          .job-detail{
            .job-detail-head .head-item{
              width: 25%;
            }
            .job-detail-info .job-detail-content{
              width: 85%;
              padding-right: 40px;
            }
          }
        }
      }
      .custom  .custom-img{
        width:95%;
      }
      .team{
        .team-map{
          height: 190px;
        }
        .info-group{
          margin-left:165px;
        }
        .team-member .member-group{
          padding-left:165px;
        }
      }
    }
    .concat{
      .bg-position{
        >div{
          width: 90%;
          margin-left:20%;
        }
      }
    }
  }
  @media (max-width:992px){
    
    .tab-group .tab-item{
        width:165px;
    }
    .tab-content{
      .join .job-detail-content{
        width: 84%!important;
        padding-right: 80px!important;
      }
      .team{
        .team-map{
          height: 180px;
        }
        .info-group{
          margin-left:100px;
        }
        .team-member .member-group{
          padding-left:100px;
        }
      }
     
    }
    .concat{
      .bg-position{
        >div{
          width: 90%;
          margin-left:10%;
        }
      }
    }
  }
}