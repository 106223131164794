.navbar{
  position:fixed;
  top:0px;
  left:0;
  right:0;
  z-index:2050;
  height:70px;
  padding:0px 8%;
  min-width:800px;
  display: flex;
  justify-content:space-between;
  background-color: transparent;
  .logo{
    height: 40px;
    margin-top:15px;
    cursor: pointer;
  }
  .navbar-menu-list{
    ul,li{
      list-style: none;
      padding:0;
    }
    ul{
      display: flex;
      height:70px;
      li{
        height: 100%;
        line-height: 70px;
        padding:0 10px;
        position:relative;
        color:#fff;
        font-size:16px;
        letter-spacing: 0.02px;
        font-weight: 200;
        cursor:pointer;
        .navbar-hover-group{
          visibility: hidden;
          opacity:0;
        }
        &:hover{
          .navbar-hover-group{
            visibility: visible;
            opacity:1;
            line-height:initial;
          }
        }
        .icon-down{
          font-size:12px;
          margin-left:3px;
        }
        .navbar-hover-group{
          position:absolute;
          padding:10px 20px;
          background: white;
          border-radius:8px;
          z-index:2051;
          top:50px;
          box-shadow: 0 2px 15px 0 rgba(0,0,0,0.10);
          white-space: nowrap;
          -webkit-transition: all 100ms linear;
          -moz-transition: all 100ms linear;
          -ms-transition: all 100ms linear;
          -o-transition: all 100ms linear;
          transition: all 100ms linear;
          
          .navbar-group-sub{
            .navbar-hover-group-item{
              color:#454545;
              font-size:14px;
              line-height: 30px;
              min-width: 100px;
              text-align: center;
              &:hover{
                color: #FD781D;
              }
            }
            .navbar-hover-group-sub-title{
              text-align: center;
              font-size: 14px;
              color:#c7c7c7;

              line-height:30px;
              min-width:100px;
              border-bottom:1px solid #dedede
            }
          }
        }
      }
    }
  }

  .btn{
    padding:5px 20px;
    border-radius: 16px;
    display: inline-block;
  }
  .btn-orange{
    -webkit-transition: all 100ms linear;
    -moz-transition: all 100ms linear ;
    -ms-transition: all 100ms linear ;
    -o-transition: all 100ms linear ;
    transition: all 100ms linear ;
    border:1px solid #f96119;
    background-color: #f96119;
    color:#fff;
    border-radius:16px;
    >a{
      color:#fff
    }
  }
  .btn-orange:hover{
    transform: translateY(-1px);
    color:#FB6A00;
    background-color: #ff8700;
    box-shadow: 0 2px 20px 0 rgba(242,150,42,0.20);
    border:1px solid #ff8700;
    >a{
      color:#FB6A00;
    }
  }
}
.opacity{
  background: #fff;
  .navbar-menu-list{
    ul{
      li{
        color:#5a5a5a;
      }
    }
  }
}