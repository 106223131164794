.vlog-wrapper{
  background-color: #fff;
	// overflow-x: hidden;
  
  .page-banner-vlog{
    position:relative;
    width:100%;
    height:300px;
    background-image: linear-gradient(-90deg, #FFA948 0%, #FFC900 100%);
    text-align: center;
    .bg{
      position:absolute;
      height:100%;
    }
    .bg.l{
      left:0px;
    } 
    .bg.r{
      right:0px;
    }
    .banner-info{
      position:relative;
      top:50px;
      .main-title{
        display: inline-block;
        position:relative;
        font-size: 42px;
        color: #FFFFFF;
        letter-spacing: 4.2px;
        margin-right:100px;
      }
      .headimg {
        position:relative;
        height:226px;
        top:10px;
      }
    }
    
  }
}