.about-us-wrapper-mobile{
  background: #fafafa;
  .btn{
    padding:0.12rem 0.7rem;
    border-radius: 0.5rem;
    display: inline-block;
    cursor: pointer;
    font-size: 0.28rem;
    letter-spacing: 0.01rem;
    
  }
  
  .btn-orange{
    border:1px solid #f96119;
    background-color: #f96119;
    color:white;
    border-radius: 0.5rem;
  }
  .btn-orange-hollow{
    background-color:transparent;
    border: 1px solid #FB6A00;
    color:#FB6A00;
  }
  .bg-position{
    position:relative;
    width:100%;
    left:50%;
    height:100%;
    transform: translateX(-50%);
  }
  .banner{
    position:relative;
    height:5rem;
    width:100%;
    margin-top:0.96rem;
    overflow: hidden;
    background: #000;
    background-image: url(../../../assets/images/v2/about/banner-bg.jpeg) ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    .bg{
      position:absolute;
      width:100%;
      bottom:-108%;
    }
    .mask-about{
      position:absolute;
      left:0px;
      right:0px;
      top:0px;
      bottom:0px;
      width:100%;
      height:100%;
      background: rgba(65, 98, 167, 0.50);
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .main-title{
        font-size: 0.42rem;
        color: #FFFFFF;
        letter-spacing: 0.042rem;
      }
      .banner-info{
        font-family: PingFangSC-Regular;
        font-size: 0.3rem;
        color: #FFFFFF;
        font-weight: 400;
        margin-top: 0.26rem;
      }
    }
  
  
  }
  .tab-group{
    position:relative;
    white-space: nowrap;
    text-align: center;
    .tab-item{
      display: inline-block;
      width:1.4rem;
      height:0.8rem;
      border-bottom:0.04rem solid transparent;
      line-height: 0.8rem;
      font-family: PingFangSC-Semibold;
      font-size: 0.29rem;
      color: #454545;
      cursor: pointer;
      &:nth-child(3){
        width: 1.6rem;
      }
      &:hover{
        color:#FD781D;
      }
    }
    .tab-item.active{
      background: white;
      border-bottom:0.04rem solid #FD781D;
      color:#FD781D;
    }
  }
  .tab-content{
    position:relative;
    .company{
      background: white;
      padding:0.7rem 0;
      text-align: center;
      .com-title{
        font-family: PingFangSC-Medium;
        font-size: 0.36rem;
        color: #454545;
        font-weight: 500;
        .logo{
          height:0.42rem;
        }
        span{
          vertical-align: sub;
        }
      }
      .com-info{
        position: relative;
        left:50%;
        transform: translateX(-50%);
        width:92%;
        text-align: left;
        font-family: PingFangSC-Regular;
        font-size: 0.28rem;
        color: #454545;
        letter-spacing: 0.014rem;
        margin-top:0.22rem;
      }
      .margin30{
        margin-top:0.3rem;
      }
      .btn-box{
        margin-top:0.3rem;
        .btn-orange{
          margin-right:0.5rem;
        }
      }
      .com-honor-head{
        white-space: nowrap;
        text-align: left;
        width:80%;
        margin-top:0.4rem;
        margin-left:2%;
        vertical-align: middle;
        .icon{
          display: inline-block;
          vertical-align: middle;
          height:0.35rem;
        }
        .text{
          display: inline-block;
          vertical-align: middle;
          font-family: PingFangSC-Medium;
          font-size: 0.36rem;
          color: #454545;
          letter-spacing: 0.024rem;
        }
        hr{
          position:relative;
          top:0.17rem;
          display: inline-block;
          width:90%;
          margin:0.2rem 0 0.2rem 0;
          border: 0;
          border-top: 1px solid #eee;
        }
      
      }
      .com-honor-list{
        margin-top:0.25rem;
        height:8rem;
        width:85%;
        margin-left:7%;
        text-align: left;
        overflow: scroll;
        .list-item{
          .honor-time{
            display: inline-block;
            width:3rem;
            font-family: PingFangSC-Semibold;
            font-size: 16px;
            color: #454545;
            text-align: justify;
            font-weight: 600;
            height: 0.7rem;
            line-height: 0.7rem;
          }
          .honor-info{
            vertical-align: top;
            display: inline-block;
            width:90%;
            font-family: PingFangSC-Regular;
            font-size: 0.28rem;
            color: #454545;
            letter-spacing: 0.014rem;
            line-height: 0.5rem;
          }
          .fg{
            width:2rem;
            height:0.04rem;
            background:#ff8700;
            border-radius:0.1rem;
            margin:0.15rem 0px;
          }
        
        } 
      }
    }
    .team{
      background: white;
      padding-bottom:0.35rem;
      .team-map{
        width: 100%;
        position:absolute;
        right:0px;
      }
      .team-head{
        transition: all 450ms linear;
      }
      .team-head.open{
        .info-group{
          .info-content{
            font-family: PingFangSC-Regular;
            font-size: 0.28rem;
            color: #454545;
            text-align: justify;
            font-weight: 400;
            .padding30{
              padding-top: 0.3rem;
            }
          }
          >div{
            &:last-child{
              margin-top:0.3rem;
              text-align: center;
              .btn-orange{
                margin-right:0.5rem;
              }
            }
          }
        }
      }
      .info-group{
        width:90%;
        margin: 0 auto;
        padding:0.8rem 0;
      }
      .team-member{
        .member-group {
          .head-title{
            font-family: PingFangSC-Medium;
            font-size: 24px;
            color: #454545;
            letter-spacing: 2.4px;
          }
          .main-member{
            padding:0 0.3rem;
            .headimg{
              width:3rem;
              vertical-align: top; 
              margin-bottom:0.35rem;
            }
            .member-info{
              position: relative;
              display: inline-block;
              vertical-align: top;
              .title{
                font-family: PingFangSC-Medium;
                font-size: 0.36rem;
                color: #454545;
              }  
              .desc{
                margin-top: 0.5rem;
                font-size: 0.28rem;
                color: #454545;
                text-align: justify;
                font-weight: 400;
                .desc-item{
                  font-family: PingFangSC-Regular;
                  color: #454545;
                  text-align: justify;
                  font-weight: 400;
                  padding-top: 0.4rem;
                }
              }
            }
          }
         
        }
      }
    }
    .custom{
      background: white;
      padding:0.6rem 0px;
      text-align: center;
      .custom-title{
        margin-top:0.5rem;
        font-family: PingFangSC-Semibold;
        font-size: 0.32rem;
        color: #454545;
        font-weight: 600;
      }
      .custom-img{
        width: 100%;
        margin-top: 0.3rem;
      }
    
    
    }
    .join{
      background: white;
      padding:0.4rem 0px;
      .join-head{
        width:100%;
        display: flex;
        flex-direction: column;
        .head-left{
          text-align: left;
          padding-left:0.5rem;
          order: 1;
          .head-text{
            font-family: PingFangSC-Semibold;
            font-size: 0.3rem;
            color: #454545;
            letter-spacing: 0.016rem;
            font-weight: 600;
          }
          .btn-box{
            margin-top:0.3rem;
            .btn-orange{
              margin-right:0.45rem;
            }
          }
          
        }

        .head-right{
          display: inline-block;
          text-align: center;
          img{
            width: 90%;
          }
        }
      }
      .row{
        width: 100%;
        text-align: center;
        padding:0.4rem 0.3rem;
        display: flex;
        justify-content: center;
        .ant-row{
          display: flex;
          justify-content: space-between;
        }
      }
      .goodness-card{
        position:relative;
        width:100%;
        // height:2.5rem;
        background: #FFFFFF;
        box-shadow: 0 0.02rem 0.1rem 0 rgba(0,0,0,0.10);
        border-radius: 0.1rem;
        padding:0.2rem 0.3rem;

        margin-bottom:0.3rem;
        &:nth-child(2){
          background: #000;
        }
        .c-top{
          position:relative;
          height:0.7rem;
          .c-title{
            position:absolute;
            top:50%;
            transform: translateY(-50%);
            font-family: PingFangSC-Semibold;
            font-size: 0.3rem;
            color: #454545;
            letter-spacing: 0.016rem;
          }
          .c-logo{
            position:absolute;
            right:0px;
            width:0.6rem;
        } 
        }
        .c-detail{
          margin-top:0.1rem;
          font-family: PingFangSC-Regular;
          font-size: 0.26rem;
          color: #454545;
          letter-spacing: 0.013rem;
          text-align: left;
        }
      }
      .job-info{
        // width:90%;
        text-align: center;
        padding-top:0.5rem;
        margin: 0 auto;
        .main-title{
          font-family: PingFangSC-Medium;
          font-size: 0.32rem;
          color: #454545;
          font-weight: 500;
          text-align: center;
        }
        .margin80{
          margin-top: 1rem;
        }
        .location-group{
          margin-top:0.2rem;
          .location-item{
            display: inline-block;
            width: 19%;
            text-align: center;
            .text{
              margin-top:0.3rem;
              font-family: PingFangSC-Regular;
              font-size: 0.27rem;
              color: #545454;
              letter-spacing: 0;
              font-weight: 400;
            }
            .png{
              height:1.2rem;
            }
          
          }
          .location-item.active{
            .text{
              color:#FD781D;
            }
          }
        }
        .job-detail .job-detail-info{
          display: none;
        }
      
      }
    
    }

  }
  .job-detail{
    .job-detail-head{
      display: flex;
      justify-content: center;
      font-size: 0.28rem;
      .head-item{
        width: 23%;
        text-align: center;
        height: 0.75rem;
        line-height: 0.75rem;
        border-bottom:1px solid #eee;
        
      }
      .active{
        border-bottom:0.04rem solid #FD781D;
      }
    }
    .job-detail-content{
      text-align: left;
      .ant-collapse-borderless {
        background: #fff;
        margin-top:0.2rem;
      }
      .ant-collapse-content{
        background: #fafafa;
        width: 90%;
        margin:0 auto;
      }
      .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text{
        flex: none;
        font-size: 0.28rem;
      }
      .ant-collapse-borderless > .ant-collapse-item,.ant-collapse > .ant-collapse-item {
        border:none
      }
      .sub-title{
        font-size: 0.3rem;
        font-weight: 500;
        margin-top:0.4rem;
        margin:0.4rem 0 0.1rem;
      }
      
    }
  }
 
  .concat{
    background: white;
    padding:0.8rem 0px;
    .form-content{
      display: inline-block;
      background: #F8F0EC;
      padding:0.2rem;
      input,textarea{
        border:0px;
        margin-bottom:0.2rem;
        border-radius:0.1rem;
      }
    }
    .service-content{
      width: 90%;
      margin:0 auto;
      .text{
        margin-bottom:0.3rem;
        font-family: PingFangSC-Regular;
        font-size: 0.28rem;
        color: #454545;
        letter-spacing: 0.014rem;
      }
      .png-group{
        margin-top:0.5rem;
        text-align: center;
        width:3.2rem;
        img{
          width:2rem;
        }
        .text{
          font-family: PingFangSC-Regular;
          font-size: 0.28rem;
          color: #454545;
          letter-spacing: 0.14rem;
        }
      }
    }
  
  }
}