@charset "UTF-8";
/*** color config ***/
/* common*/
/*nav*/
/*menu*/
/**content*/
body {
  font-family: PingFangSC, "微软雅黑", "黑体", helvetica, Arial, san-serif !important;
}

.home-page-wrapper .m-t, .product-market-wrapper .m-t, .case-market .m-t, .about-us-wrapper .m-t {
  margin-top: 20px;
}
.home-page-wrapper .main-title, .product-market-wrapper .main-title, .case-market .main-title, .about-us-wrapper .main-title {
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #443973;
  letter-spacing: 3.6px;
}
.home-page-wrapper .sub-title, .product-market-wrapper .sub-title, .case-market .sub-title, .about-us-wrapper .sub-title {
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #454545;
  letter-spacing: 1.6px;
}
.home-page-wrapper .info-content, .product-market-wrapper .info-content, .case-market .info-content, .about-us-wrapper .info-content {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #454545;
  letter-spacing: 1.4px;
}
.home-page-wrapper .btn-box, .product-market-wrapper .btn-box, .case-market .btn-box, .about-us-wrapper .btn-box {
  margin-top: 20px;
}
.home-page-wrapper .btn, .product-market-wrapper .btn, .case-market .btn, .about-us-wrapper .btn {
  padding: 5px 20px;
  border-radius: 16px;
  display: inline-block;
  cursor: pointer;
}
.home-page-wrapper .btn-orange, .product-market-wrapper .btn-orange, .case-market .btn-orange, .about-us-wrapper .btn-orange {
  -webkit-transition: all 100ms linear;
  -moz-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  -o-transition: all 100ms linear;
  transition: all 100ms linear;
  border: 1px solid #f96119;
  background-color: #f96119;
  color: white;
  border-radius: 16px;
}
.home-page-wrapper .btn-orange[disabled], .product-market-wrapper .btn-orange[disabled], .case-market .btn-orange[disabled], .about-us-wrapper .btn-orange[disabled] {
  color: #FB6A00;
}
.home-page-wrapper .btn-orange:hover, .product-market-wrapper .btn-orange:hover, .case-market .btn-orange:hover, .about-us-wrapper .btn-orange:hover {
  transform: translateY(-1px);
  color: #fff;
  background-color: #ff8700;
  box-shadow: 0 2px 20px 0 rgba(242, 150, 42, 0.2);
  border: 1px solid #ff8700;
}
.home-page-wrapper .btn-orange-hollow, .product-market-wrapper .btn-orange-hollow, .case-market .btn-orange-hollow, .about-us-wrapper .btn-orange-hollow {
  -webkit-transition: all 100ms linear;
  -moz-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  -o-transition: all 100ms linear;
  transition: all 100ms linear;
  background-color: transparent;
  border: 1px solid #FB6A00;
  color: #FB6A00;
}
.home-page-wrapper .btn-orange-hollow:hover, .product-market-wrapper .btn-orange-hollow:hover, .case-market .btn-orange-hollow:hover, .about-us-wrapper .btn-orange-hollow:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 20px 0 rgba(242, 150, 42, 0.2);
  background-color: transparent;
  border: 1px solid #FB6A00;
  color: #FB6A00;
}
.home-page-wrapper .case-card, .product-market-wrapper .case-card, .case-market .case-card, .about-us-wrapper .case-card {
  position: relative;
  width: 294px;
  height: 186px;
  overflow: hidden;
  display: inline-block;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
  cursor: pointer;
}
.home-page-wrapper .case-card .case-card-bg, .product-market-wrapper .case-card .case-card-bg, .case-market .case-card .case-card-bg, .about-us-wrapper .case-card .case-card-bg {
  position: absolute;
  left: 0;
  width: 100%;
}
.home-page-wrapper .case-card .case-card-icon, .product-market-wrapper .case-card .case-card-icon, .case-market .case-card .case-card-icon, .about-us-wrapper .case-card .case-card-icon {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}
.home-page-wrapper .case-card .text, .product-market-wrapper .case-card .text, .case-market .case-card .text, .about-us-wrapper .case-card .text {
  width: 100%;
  position: absolute;
  bottom: 30px;
  text-align: center;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 1.6px;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}
.home-page-wrapper .case-card .case-bottom, .product-market-wrapper .case-card .case-bottom, .case-market .case-card .case-bottom, .about-us-wrapper .case-card .case-bottom {
  position: absolute;
  bottom: -40px;
  width: 100%;
  height: 40px;
  background: rgba(0, 0, 0, 0.53);
  text-align: center;
  color: white;
  line-height: 40px;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 1.6px;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}
.home-page-wrapper .case-card .case-bottom a, .home-page-wrapper .case-card .case-bottom a:hover, .product-market-wrapper .case-card .case-bottom a, .product-market-wrapper .case-card .case-bottom a:hover, .case-market .case-card .case-bottom a, .case-market .case-card .case-bottom a:hover, .about-us-wrapper .case-card .case-bottom a, .about-us-wrapper .case-card .case-bottom a:hover {
  color: white;
}
.home-page-wrapper .case-card:hover, .product-market-wrapper .case-card:hover, .case-market .case-card:hover, .about-us-wrapper .case-card:hover {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
}
.home-page-wrapper .case-card:hover .case-card-icon, .product-market-wrapper .case-card:hover .case-card-icon, .case-market .case-card:hover .case-card-icon, .about-us-wrapper .case-card:hover .case-card-icon {
  transform: translate(-50%, -20px);
}
.home-page-wrapper .case-card:hover .text, .product-market-wrapper .case-card:hover .text, .case-market .case-card:hover .text, .about-us-wrapper .case-card:hover .text {
  transform: translate(0px, -20px);
}
.home-page-wrapper .case-card:hover .case-bottom, .product-market-wrapper .case-card:hover .case-bottom, .case-market .case-card:hover .case-bottom, .about-us-wrapper .case-card:hover .case-bottom {
  transform: translate(0px, -40px);
}
.home-page-wrapper .sub-main-title, .product-market-wrapper .sub-main-title, .case-market .sub-main-title, .about-us-wrapper .sub-main-title {
  font-family: PingFangSC-Medium;
  font-size: 24px;
  color: #443973;
  letter-spacing: 2.4px;
}
.home-page-wrapper .cycle-banner, .product-market-wrapper .cycle-banner, .case-market .cycle-banner, .about-us-wrapper .cycle-banner {
  position: relative;
  margin-top: 20px;
  width: 90%;
  margin-left: 5%;
  height: 430px;
  background: #FFFFFF;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  overflow: hidden;
}
.home-page-wrapper .cycle-banner .banner-bg, .product-market-wrapper .cycle-banner .banner-bg, .case-market .cycle-banner .banner-bg, .about-us-wrapper .cycle-banner .banner-bg {
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 380px;
}
.home-page-wrapper .cycle-banner .cycle-content, .product-market-wrapper .cycle-banner .cycle-content, .case-market .cycle-banner .cycle-content, .about-us-wrapper .cycle-banner .cycle-content {
  white-space: nowrap;
  height: 100%;
  transition: all 500ms linear;
}
.home-page-wrapper .cycle-banner .cycle-content .cycle-item, .product-market-wrapper .cycle-banner .cycle-content .cycle-item, .case-market .cycle-banner .cycle-content .cycle-item, .about-us-wrapper .cycle-banner .cycle-content .cycle-item {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.home-page-wrapper .cycle-banner .cycle-content .cycle-item > img, .product-market-wrapper .cycle-banner .cycle-content .cycle-item > img, .case-market .cycle-banner .cycle-content .cycle-item > img, .about-us-wrapper .cycle-banner .cycle-content .cycle-item > img {
  display: none;
}
.home-page-wrapper .cycle-banner .cycle-content .cycle-item .info-content, .product-market-wrapper .cycle-banner .cycle-content .cycle-item .info-content, .case-market .cycle-banner .cycle-content .cycle-item .info-content, .about-us-wrapper .cycle-banner .cycle-content .cycle-item .info-content {
  color: #454545;
}
.home-page-wrapper .cycle-banner .cycle-content .cycle-item .info-group, .product-market-wrapper .cycle-banner .cycle-content .cycle-item .info-group, .case-market .cycle-banner .cycle-content .cycle-item .info-group, .about-us-wrapper .cycle-banner .cycle-content .cycle-item .info-group {
  white-space: initial;
  text-align: left;
  width: 30%;
  left: 15%;
  min-width: 325px;
}
.home-page-wrapper .cycle-banner .cycle-content .cycle-item .info-group .main-title, .product-market-wrapper .cycle-banner .cycle-content .cycle-item .info-group .main-title, .case-market .cycle-banner .cycle-content .cycle-item .info-group .main-title, .about-us-wrapper .cycle-banner .cycle-content .cycle-item .info-group .main-title {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #454545;
  letter-spacing: 3.6px;
}
.home-page-wrapper .cycle-banner .cycle-content .cycle-item .lift-info, .product-market-wrapper .cycle-banner .cycle-content .cycle-item .lift-info, .case-market .cycle-banner .cycle-content .cycle-item .lift-info, .about-us-wrapper .cycle-banner .cycle-content .cycle-item .lift-info {
  white-space: nowrap;
  position: absolute;
  top: 200px;
  right: 4%;
}
.home-page-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item, .product-market-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item, .case-market .cycle-banner .cycle-content .cycle-item .lift-info .info-item, .about-us-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item {
  display: inline-block;
  margin-right: 40px;
}
.home-page-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item .text, .product-market-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item .text, .case-market .cycle-banner .cycle-content .cycle-item .lift-info .info-item .text, .about-us-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item .text {
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #454545;
  letter-spacing: 1.6px;
}
.home-page-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item .rate, .product-market-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item .rate, .case-market .cycle-banner .cycle-content .cycle-item .lift-info .info-item .rate, .about-us-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item .rate {
  margin-top: 10px;
  width: 215px;
  height: 54px;
  line-height: 54px;
  font-family: PingFangSC-Medium;
  font-size: 24px;
  color: #FFFFFF;
  letter-spacing: 2.4px;
  background: #4162A7;
  border-radius: 8px;
}
.home-page-wrapper .cycle-banner .arrow, .product-market-wrapper .cycle-banner .arrow, .case-market .cycle-banner .arrow, .about-us-wrapper .cycle-banner .arrow {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  font-size: 30px;
  cursor: pointer;
}
.home-page-wrapper .cycle-banner .arrow:hover i, .product-market-wrapper .cycle-banner .arrow:hover i, .case-market .cycle-banner .arrow:hover i, .about-us-wrapper .cycle-banner .arrow:hover i {
  color: #FD781D;
}
.home-page-wrapper .cycle-banner .arrow.left, .product-market-wrapper .cycle-banner .arrow.left, .case-market .cycle-banner .arrow.left, .about-us-wrapper .cycle-banner .arrow.left {
  right: 10px;
}
.home-page-wrapper .cycle-banner .arrow.right, .product-market-wrapper .cycle-banner .arrow.right, .case-market .cycle-banner .arrow.right, .about-us-wrapper .cycle-banner .arrow.right {
  left: 10px;
}
.home-page-wrapper .fadeDown, .product-market-wrapper .fadeDown, .case-market .fadeDown, .about-us-wrapper .fadeDown {
  -webkit-animation: mymove 0.5s forwards 0.5s ease-out;
  -o-animation: mymove 0.5s forwards 0.5s ease-out;
  animation: mymove 0.5s forwards 0.5s ease-out;
}
@keyframes mymove {
  from {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@-moz-keyframes mymove /* Firefox */ {
  from {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@-webkit-keyframes mymove /* Safari 和 Chrome */ {
  from {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@-o-keyframes mymove /* Opera */ {
  from {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@media (max-width: 1200px) {
  .home-page-wrapper .cycle-banner .cycle-content .cycle-item .lift-info, .product-market-wrapper .cycle-banner .cycle-content .cycle-item .lift-info, .case-market .cycle-banner .cycle-content .cycle-item .lift-info, .about-us-wrapper .cycle-banner .cycle-content .cycle-item .lift-info {
    top: 150px;
    right: 18%;
  }
  .home-page-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item, .product-market-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item, .case-market .cycle-banner .cycle-content .cycle-item .lift-info .info-item, .about-us-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item {
    display: block;
  }
  .home-page-wrapper .btn-orange-hollow, .product-market-wrapper .btn-orange-hollow, .case-market .btn-orange-hollow, .about-us-wrapper .btn-orange-hollow {
    margin-top: 10px;
  }
}
@media (max-width: 992px) {
  .home-page-wrapper .cycle-banner .cycle-content .cycle-item .lift-info, .product-market-wrapper .cycle-banner .cycle-content .cycle-item .lift-info, .case-market .cycle-banner .cycle-content .cycle-item .lift-info, .about-us-wrapper .cycle-banner .cycle-content .cycle-item .lift-info {
    top: 150px;
    right: 8%;
  }
  .home-page-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item, .product-market-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item, .case-market .cycle-banner .cycle-content .cycle-item .lift-info .info-item, .about-us-wrapper .cycle-banner .cycle-content .cycle-item .lift-info .info-item {
    display: block;
  }
}

.scroll-top {
  display: none;
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 41px;
  height: 41px;
  background: #fb6a00;
  border: none;
  border-radius: 50%;
  text-align: center;
}
.scroll-top .up-arrow {
  position: absolute;
  top: 50%;
  margin-top: -12px;
  margin-left: -10px;
  color: #fff;
  font-size: 22px;
}
.scroll-top .mask {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  opacity: 0;
  transform: translateY(-50px);
  width: 41px;
  height: 41px;
  transition: all 0.25s;
  text-align: center;
  color: white;
  font-size: 12px;
  padding-top: 2px;
  margin-top: -5px;
  margin-left: -20px;
}

.scroll-top.in {
  display: block;
  overflow: hidden;
}

.scroll-top.in:hover .mask {
  transform: translateY(0px);
  opacity: 1;
}

.online-service {
  position: fixed;
  max-width: 30px;
  max-height: 120px;
  right: 30px;
  bottom: 24px;
  cursor: pointer;
  overflow: visible;
  opacity: 1;
  z-index: 100;
  background: #f96868;
  border-radius: 14px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);
}
.online-service img {
  width: 28px;
}