@charset "UTF-8";
html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body > #root {
  width: 100%;
}

.DTButton {
  background: #FFE6D7 !important;
  color: #ff6800 !important;
  border: none !important;
}

.DtScrollBar {
  scrollbar-arrow-color: #f2f2f3; /*上下箭头*/
  scrollbar-track-color: #1589ce; /*底层背景色*/
  scrollbar-face-color: #27aeff; /*滚动条前景色*/
  scrollbar-Shadow-color: #1589ce; /*滚动条边线色*/
}

.DtScrollBar::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  background-color: transparent;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.DtScrollBar::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/*定义滚动条轨道 内阴影+圆角*/
.DtScrollBar::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

/*定义滑块 内阴影+圆角*/
.ant-table {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0 !important;
}

.ant-input, .ant-input-group-addon, .ant-btn {
  border-radius: 6px !important;
}

.ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ant-popover-inner-content {
  color: rgba(0, 0, 0, 0.85) !important;
}