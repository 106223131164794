.case-market-mobile{
  background-color: #f2f2f2;
  .main-title{
    font-family: PingFangSC-Semibold;
    color: #443973;
    letter-spacing: 0.036rem;
    font-size:0.32rem;
    font-weight:600;
  }
  .sub-title{
    font-family: PingFangSC-Semibold;
    font-size: 0.28rem;
    color: #454545;
    letter-spacing: 0.016rem;
    font-weight: 500;
  }
  
  .sub-main-title{
    font-family: PingFangSC-Medium;
    font-size: 0.28rem;
    color: #443973;
    // letter-spacing: 2.4px;
  }

  .btn{
    padding:0.12rem 0.7rem;
    border-radius: 0.5rem;
    display: inline-block;
    cursor: pointer;
    font-size: 0.28rem;
    letter-spacing: 0.01rem;
    
  }
  
  .btn-orange{
    border:1px solid #f96119;
    background-color: #f96119;
    color:white;
    border-radius: 0.5rem;
  }
  .btn-orange-hollow{
    background-color:transparent;
    border: 1px solid #FB6A00;
    color:#FB6A00;
  }
  .page-banner{
    position:relative;
    width:100%;
    height:4.65rem;
    background: #3C51AB;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:0.96rem;
    .bg{
      position:absolute;
      height:100%;
    }
    .banner-info{
      position:absolute;
      text-align: center;
      top:1.4rem;
      color:white;
      width:100%;
    }
    div.bg{
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background:url('../../../assets/images/v2/index/bg-yellow.png');
      background-repeat: repeat-x;
      background-size:auto 100%; 
    }
    .bg.l{
      left:0px;
    }
    .bg.r{
      right:0px;
    }
    .main-title{
      margin-top: -0.45rem;
      font-size: 0.42rem;
      color: #FFFFFF;
      letter-spacing: 0.042rem;
      font-weight: 600;
    }
  }

  .tab-content{
    position:relative;
    height:auto;
    width:100%;
    .content-container{
      position:relative;
      width:100%;
      background: white;
      padding:0  0.25rem  0.8rem;
      .info-group{
        font-size: 0.28rem;
        .main-title{
          text-align: center;
          font-size: 0.32rem;
          font-weight: bold;
        }
        .btn-box{
          text-align: center;
          margin-top:0.3rem;
        }
        .retail-btn{
          margin-right:0.7rem;
        }
      }
      .info-content{
        margin-top:0.3rem;
      }
      .bg{
        text-align: center;
        img{
          width: 95%;
          margin:0.7rem auto;
        }
      }
      .img-a{
        width: 1rem;
        height:0.5rem;
        position:absolute;
        top:0;
        border:none;
        display:inline-block
      }
      .a1{
        left:65%;
        top:0.7rem;
       
      }
      .a2{
        top:0.7rem;
        left:32%;
      }
      .a3{
        left:85%;
        top:0.7rem;
      }
    }

    .case-switcher{
      position: relative;
      height:0.7rem;
      width:100%;
      white-space: nowrap;
      text-align: center;
      .bg-switcher-bar{
        position:absolute;
        top:0px;
        width: 2.3rem;
        height: 0.7rem;
        border-radius: 0.4rem;
        left:50%;
        margin-left:-1.2rem;
        transform: translateX(-2.3rem);
        transition:all 500ms ease-out;
      }
      .bg-switcher-bar.color-orange{
        background-color:#ff6800;
      }
      .bg-switcher-bar.color-blue{
        background-color:#4A90E2;
      }
      .case-switcher-item{
        position: relative;
        width: 2.3rem;
        height: 0.7rem;
        text-align: center;
        display: inline-block;
        cursor:pointer;
        
        .case-switcher-item_inner{
          display: inline-block;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          >img{
            width: 0.4rem;
            margin-right:0.1rem;
            vertical-align: middle;
          }
          .case-switcher-item_inner-text-container{
            vertical-align: middle;
            display: inline-block;
            text-align: center;
            letter-spacing: 1px;
            .case-switcher-item_inner-text{
              height:0.24rem;
              font-size:0.24rem;
              font-family:PingFangSC-Semibold;
              font-weight:600;
              color:rgba(69,69,69,1);
              line-height:24px;
              line-height: 0.22rem;
            } 
            .case-switcher-item_inner-subtext{
              font-size:0.2rem;
              font-family:PingFangSC-Semibold;
              font-weight:600;
              color:rgba(69,69,69,1);
            }
          }
        }
      }
  
      .case-switcher-item.active {
        .case-switcher-item_inner-text-container{
          .case-switcher-item_inner-text,.case-switcher-item_inner-subtext{
            transition: all 200ms linear;
            color:white;
          }
        }
      }  
    }
    .case-switcher.b0 .bg-switcher-bar{
      transform: translateX(-2.3rem);
    }
    .case-switcher.b1 .bg-switcher-bar{
      transform: translateX(0px);
    }
    .case-switcher.b2 .bg-switcher-bar{
      transform: translateX(2.3rem);
    }

  }
  .background-info{
    position:relative;
    width:100%;
    padding:0.5rem 0.25rem;
    text-align: center;
    background: white;
    .bg-content{
      display: inline-block;
      text-align: initial;
    }
    .main-title{
      font-weight: bold;
      font-size:0.32rem;
      width: 100%;
      text-align: center;
      margin:0.1rem 0 0.25rem 0;
    }
    .bg-detail{
      position:relative;
      display: inline-block;
      vertical-align: top;
      text-align: left;
      .detail-title{
        font-size: 0.28rem;
        color: #443973;
        text-align: center;
        letter-spacing: 0.024rem;

      }
      .detail-info{
        margin-top:0.75rem;
        font-size: 0.28rem;
        color: #454545;
        letter-spacing: 0.014rem;
      }
      .two{
        margin-top:0.4rem;
      }
      .detail-more {
      
        .more-btn{
          cursor: pointer;
          margin-top:0.05rem;
          font-size: 0.28rem;
          color: #FD781D;
          letter-spacing: 0.014rem;
        }
      }
      .chart-more{
        margin-top:0.1rem;
        height:0;
        transition: all 500ms linear;
        overflow: hidden;
        text-align:center;
      }
      .chart-more.active.sale{
        height:2.8rem;
      }
      .chart-more.active.bank{
        height:5rem;
      }
      .chart-more.active.baoxian{
        height:6.88rem;
      }
      .chart-more.active.haier{
        height:4.18rem;
      }

      .detail-pg{
        white-space: nowrap;
        background: #dedede;
        .detail-pb-container{
          width: 100%;
          background: white;
          text-align: center;
          vertical-align: top;
          .text{
            font-size: 0.28rem;
            color: #454545;
            letter-spacing: 0.016rem;
          }
        }
        .detail-pb-container:nth-child(1) .pg{
          margin-top:0.45rem;
          width:3.6rem;
        }
        .detail-pb-container:nth-child(1) .text{
          margin-top:0.15rem;
        }
        .detail-pb-container:nth-child(2) .pg{
          margin-top:0.5rem;
          width:2.9rem;
        }
        .detail-pb-container:nth-child(2) .text{
          margin-top:0.15rem;
        
        }
        .detail-pb-container:nth-child(3) .pg{
          margin-top:0.7rem;
          width:2.8rem;
        }
        .detail-pb-container:nth-child(3) .text{
          margin-top:0.1rem;
        }
      }
    }
  }
  
  .case-switcher-content{
    visibility: hidden;
    opacity:0;

  }
  .case-switcher-content.active{
    transition:all 500ms linear;
    visibility:visible;
    opacity:1;
  }

  .solution-info{
    position:relative;
    width:100%;
    background: white;
    text-align: center;
    padding:0.5rem 0.25rem;
    overflow: hidden;
    .info-w{
      >div{
        margin-top:0.25rem;
      }
    }
    .main-title{
      font-weight: bold;
      font-size: 0.29rem;
    }

    .sol-img{
      width: 100%;
    }
    .w-2{
      .sub-title{
        margin-bottom:0.2rem;
      }
    }
  }
  .bank-solution,.insurance-solution{
    .bg-position{
      >div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .left{
          order:1;
          white-space: initial;
          font-size: 0.28rem;
          .q-group{
            display: block;
          }
          .q-icon,.q-title{
            display: inline-block;
            font-size: 0.29rem;
          }
          .q-text{
            padding-left:0.1rem;
            margin:0.05rem 0 ;
          }
        }
        .right{
          img{
            width: 100%;
          }
        }
      }
    }
  }
  .smart-solution-info{
    position:relative;
    text-align: center;
    width:100%;
    padding-top:0.56rem;
    .main-title{
      font-size: 0.29rem;
      font-weight: 600;
    }
    .tab-group{
      margin-top:0.3rem;
      white-space: nowrap;
      text-align: center;
      // background: #000;
      .tab-item{
        display: inline-block;
        width:1.3rem;
        height:0.55rem;
        line-height: 0.55rem;
        font-size: 0.22rem;
        color: #454545;
        // letter-spacing: 0.016rem;
        cursor:pointer;
        // background: pink;
        margin-right:1px;
        &:last-child{
          width: 1.8rem;
        }
      }
      .tab-item:hover{
        color:#FD781D;
      }
      .tab-item.active{
        background: white;
        color:#FD781D;
      }
    }
    .sol-tab-content{
      width:100%;
      background: white;
      padding: 0.5rem 0.25rem;
      .bg-position{
        display: none;
        opacity:0;
      }
      .bg-position.active{
        -webkit-transition: all 500ms linear;
        -moz-transition: all 500ms linear;
        -ms-transition: all 500ms linear;
        -o-transition: all 500ms linear;
        transition: all 500ms linear;
        display: block;
        opacity:1;
      }
      .q-s-group{
        // position:absolute;
        // left:80px;
        // top:50%;
        // transform: translateY(-50%);
        // width:31%;
      }
    }
  }
  .q-s-group{
    text-align: left;
    .q-group,.s-group{
      position:relative;
      display: flex;
      :nth-child(2){
        flex: 1;
      }
      .q-title,.s-title{
        font-size: 0.26rem;
        letter-spacing: 0.016;
      }
      .q-text,.s-text{
        width: 100%;
        font-size: 0.26rem;
      }
      .q-icon,.s-icon{
        width:0.35rem;
        height:0.35rem;
        color:white;
        border-radius:50%;
        text-align: center;
        line-height:0.35rem;
        font-size:0.22rem;
        position: relative;
        margin-right:0.1rem;
       
      }
      .s-icon:before {
        position: absolute;
        content: '';
        width: 0.12rem;
        height: 0.18rem;
        transform: rotate(45deg);
        border-right: 0.04rem solid #fff;
        border-bottom: 0.04rem solid #fff;
        top: 50%;
        right: 0.12rem;
        margin-top:-0.12rem;
        z-index: 2;
      }
    }
   
    .q-group{
      .q-title{
        color: #454545;
      }
      .q-text{
        color: #7F7F7F;
      }
      .q-icon{
        background: #454545;
      }
    }
    .g-1{
      margin-top:1rem;
    }
    .s-group {
      .s-title{
        color: #4162A7;
      }
      .s-text{
        color: #4162A7;
      }
      .s-icon{
        background: #FD781D;
      }
      .s-text.big{
        font-size: 0.26rem;
        color: #4162A7;
      }
    }
  }
  .smart-solution-info{
    .sol-tab-content{
      .center-bg{
        width: 80%;
        visibility: hidden;
        opacity:0;
        margin-top:0.5rem;
      }
      .center-ana-bg2{
        width: auto;
        position:absolute;
        right:0;
        height:2rem;
        bottom:0.1rem;
      }
      .center-ai-bg2{
        bottom:1.4rem;
        right:0.35rem;
        width: auto;
        position:absolute;
        height:1rem;
      }
      .center-bg.active{
        -webkit-transition: all 500ms linear 500ms;
        -moz-transition: all 500ms linear 500ms;
        -ms-transition: all 500ms linear 500ms;
        -o-transition: all 500ms linear 500ms;
        transition: all 500ms linear 500ms;
        visibility: visible;
        opacity:1;
      }
      .center-ai-desc{
        width: 100%;
        position:absolute;
        font-size: 0.22rem;
        color: #4162A7;
        bottom:3.35rem;
        .big{
          font-size:0.32rem;
        }
      }
    } 
  } 
  .bank-smart{
    padding:0.2rem;
    font-size:0.28rem;
    .small{
      >img{
        width: 100%;
      }
    }
    .main-title{
      font-size:0.32rem;
      margin:0.5rem;
    }
  }
  .insurance-smart{
    padding:0.3rem ;
    .main-title{
      font-size: 0.32rem;
      margin:0.4rem 0;
    }
    .smart-solution-text{
      text-align: left;
      font-size: 0.28rem;
      margin-bottom:0.15rem;
    }
    .img-group{
      margin-top:0.5rem;
      .img-container{
        margin:0 auto;
        .i-title{
          font-size: 0.3rem;
        }
        >img{
          width: 3rem;
          margin-top:0.39rem;
        }
        &:last-child{
          padding-top:0.6rem;
          >img{
            width: 1.8rem;
            margin:0;
          }
        }
      }
    }
  }
  .smart-sol-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    .smart-sol-group{
      .smart-sol-description{
        font-family: PingFangSC-Semibold;
        font-size: 0.3rem;
        color: #454545;
        text-align: justify;
        font-weight: 600; 
        text-indent:2em;
        margin-top: 0.6rem;
      }
      .smart-sol-title{
        font-family: PingFangSC-Semibold;
        font-size: 0.31rem;
        color: #454545;
        line-height: 26px;
        font-weight: 600;
        margin-top: 0.4rem
      }
      .smart-sol-title-orange{
        font-family: PingFangSC-Medium;
        font-size: 0.32rem;
        color: #F46225;
        font-weight: 500;
        margin-top: 0.4rem;
      }
      .smart-sol-text{
        font-family: PingFangSC-Regular;
        font-size: 0.28rem;
        color: #7F7F7F;
        text-align: justify;
        font-weight: 400;
        margin-top: 0.1rem;
        .orange{
          font-family: PingFang-SC-ExtraLight;
          color: #F46225;
          text-align: justify;
          font-weight: 400;
        }
      }

    }
  }
  .pro-value-info{
    position:relative;
    text-align: center;
    padding:0.6rem 0px;
    .main-title{
      font-size: 0.32rem;
    }
  }
  .pg-group{
    margin-top:0.2rem;
    display: inline-block;
    white-space: nowrap;
    .pg-item {
      display: inline-block;
      background: #FFFFFF;
      border-radius: 0.2rem;
      width:2.1rem;
      height:1.8rem;
      position: relative;
      margin-right:0.3rem;
      padding:0.26rem 0;
      &:last-child{
        margin-right:0;
      }
      img{
        width:0.85rem;
      }
      .text{
        margin-top:0.13rem;
        font-size: 0.26rem;
        color: #454545;
      }
    }
  }

  //ABtest
  .sol-info{
    position:relative;
    // height:10rem;
    padding:0.56rem 0 0.1rem 0;
    text-align: center;
    overflow: hidden;
    padding: 0.5rem 0.25rem;
    .main-title{
      font-size: 0.29rem;
      font-weight: 600;
      color: #443973;
      margin-bottom:0.3rem;
    }
    .q-s-group{
      font-size: 0.28rem;
      .q-group{
        display: flex;
      }
      .q-title,.s-title{
        margin-bottom:0.1rem;
      }
      .s-text:nth-child(3){
        margin-top:0.5rem;
      }
    }
    .bg{
      width: 93%;
    }
    .right-p{
      .bg{
        width:100%;
        height:auto;
      }
    }
  }
  .sol-info.w-bg{
    background: white;
    .s-group{
      margin-top: 0.6rem;
    }
    .right-p{
      .text{
        color:#4162A7;
        font-size: 0.24rem;
        margin: 0.5rem 0 0.15rem;
      }
    }
  }
  .sol-info.abtest .bg2{
    position:absolute;
    height:1.5rem;
    right:-0.06rem;
    bottom:0.1rem;
  }
  .sol-info.one{
    .s-group{
      margin-top: 0.6rem;
    }
  }
  //success case
  .custom-info{
    position:relative;
    background: white;
    text-align: center;
    padding:0 0.4rem 0.5rem;
    .main-title{
      font-size:0.29rem;
      padding:0.5rem 0 0.2rem;
    }
    .banner-bg{
      width: 100%;
    }
    .case-container{
      display: flex;
      justify-content: space-between;

      .case-card{
        position:relative;
        width:2.08rem;
        height:1.3rem;
        display: inline-block;
        display: flex;
        cursor: pointer;
        .case-card-bg{
          position:absolute;
          left:0;
          width:100%;
        }
        .text{
          margin-top: 0.1rem;
        }
        .case-card-icon{
          width: 0.55rem;
          position:absolute;
          top:0.25rem;
          left:50%;
          transform: translateX(-50%);
          -webkit-transition: all 300ms linear;
          -moz-transition: all 300ms linear;
          -ms-transition: all 300ms linear;
          -o-transition: all 300ms linear;
          transition: all 300ms linear;
        }
        .text{
          width:100%;
          position:absolute;
          bottom:0.1rem;
          text-align: center;
          font-family: PingFangSC-Semibold;
          font-size: 0.22rem;
          color: #FFFFFF;
          letter-spacing: 1.6px;
          -webkit-transition: all 300ms linear;
          -moz-transition: all 300ms linear;
          -ms-transition: all 300ms linear;
          -o-transition: all 300ms linear;
          transition: all 300ms linear;
        }
        .case-bottom{
          display: none;
        
        }
      }
    }
  }
  .bank-custom,.insurance-custom{
    .main-title{
      font-size: 0.32rem;
    }
    >img{
      width: 100%;
    }
    .case-group{
      // margin-top:20px;
    }
  }
  .jewelry-wrapper{
    padding:0.5rem 0.25rem;
    font-size:0.28rem;
    .jewelry-desc{
      width:100%;
      position:relative;
      >div{
        position:relative;
        width:100%;
        .desc{
          .main-title{
            font-size:0.29rem;
            text-align: center;
            font-weight: 600;
            margin-bottom:0.1rem;
          }
          .info-text{
            margin-top:0.25rem;
          }
        }
        .desc-2{
          .img-text{
            text-align: center;
            span{
              color:#ff6800
            }
          }
        }
      }
    }

    .jewelry-case{
      width:100%;
      margin-top:1rem;
      position: relative;
      .sub-main-title{
        color:rgba(68, 57, 115, 1);
        font-size:0.29rem;
        text-align: center;
        font-weight: 600;
      }
    }
    .more-case{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding:0.5rem 0;
      .main-title{
        font-size: 0.3rem;
        font-weight: 600;
      }
      img{
        width: 6rem;
      }
    }

  }
  .food-fast-sell{
    padding:0 0.25rem;
    >div{
      width: 100%;
      // height: 3709px;
      position: relative;
    }
    .food-fast-desc{
      // display: flex;
      // justify-content: center;
      padding-top:0.4rem;
      .desc-item{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        img{
          width: 70%;
        }
        .img-text{
          text-align: center;
          margin-top:0.3rem;
          span{
            color:#ff6800;
          }
        }
      }
    }
    .industry{
      margin-top:1.2rem;
      .main-title{
        text-align: center;
        margin-bottom:0.2rem;
      }
      .background{
        width: 100%;
      }
      .painSpot{
        width: 100%;
        margin-top:1rem;
      }
    }
    .b2c-case{
      width:100%;
      margin-top:1.2rem;
      >div{
        margin: 0 auto;
        .main-title{
          text-align: center;
        }
        .info-text{
          margin-top:0.35rem;
        }
      }
    }
  }
  .fast-sell-module{
    padding-top:1.1rem;
    align-items: center;
    margin:0 auto;
    .sub-main-title{
      text-align: center;
      font-weight: 600;
      margin-bottom: 0.35rem;
    }
  }
  .left-module{
    display: flex;
    flex-direction: column;
  }
  .sell-module2{
    display: flex;
    flex-direction: column;
    .left{
      order:1
    }
  }
  .info-text-li{
    width: 98%;
    position: relative;
    font-size: 0.26rem;
    font-family:PingFangSC-Semibold;
    font-weight:600;
    color:rgba(69,69,69,1);
    line-height:0.45rem;
    margin-left:0.2rem;
  }
  .info-text-li.small{
    line-height:0.26rem;
  }
  .info-text-li.regular{
    font-family:PingFangSC-Regular;
    font-weight:400;
  }
  .info-text-li.nopoint{
    margin-left:0;
  }
  .info-text-li.nopoint::before{
      display: none;
  }
  .info-text-li::before{
      content:'';
      position:absolute;
      border-radius:0.2rem;
      width:0.1rem;
      height:0.1rem;
      background: #ff6800;
      top:0.15rem;
      left:-0.15rem;
  }
  .info-text-li.blue::before{
      background: #4A90E2;
  }
  .info-text-li.small::before{
      top:0.07rem;
  }

  
  .cycle-banner{
    position:relative;
    margin-top:0.2rem;
    background: #FFFFFF;
    box-shadow: 0 0.02rem 0.2rem 0 rgba(0,0,0,0.20);
    border-radius: 0.16rem;
    overflow: hidden;
    padding:0.45rem;
    .banner-bg{
      position:absolute;
      bottom:0px;
      right:0px;
    }
    .cycle-content{
      white-space: nowrap;
      height:100%;
      transition: all 500ms linear;
      .cycle-item{
        position:relative;
        display: inline-block;
        width:100%;
        height:100%;
        .info-content{
          color:#454545;
        }
        .info-group{
          white-space: initial;
          text-align: left;
          .main-title{
            font-family: PingFangSC-Semibold;
            font-size: 0.29rem;
            color: #454545;
            padding:0;
          }
          .sub-title{
            margin:0.2rem 0;
          }
          .m-t{
            .btn{
              padding:0.05rem 0.35rem;
              margin-top:0.35rem;
              font-size: 0.26rem;
            }
          }
        }
        .lift-info{
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          .info-item{
            display: inline-block;
            margin-top:0.4rem;
            .text{
              font-family: PingFangSC-Semibold;
              font-size: 0.28rem;
              color: #454545;
            }
            .rate{
              margin-top:10px;
              width:2.4rem;
              height:0.6rem;
              line-height: 0.6rem;
              font-family: PingFangSC-Medium;
              font-size: 0.26rem;
              color: #FFFFFF;
              background: #4162A7;
              border-radius: 8px;
            }
          }
        
        }
      }
    }
  }
  .more-case-food{
    text-align:center;
    margin-top:0.3rem;
    img{
      width: 100%;
    }
  }

}