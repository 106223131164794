// .product-sale-wrapper
.product-market-wrapper{
  background-color: #f2f2f2;
	overflow-x: hidden;
  min-width: 800px;
  // 公共样式start
  .info-group{
    display: inline-block;
    text-align: left;
    width:30%;
    min-width:467px;
    white-space: initial;
    margin-right:100px;
  }
  // 公共样式end

  .banner{
    position:relative;
    width:100%;
    height:450px;
    background: #3C51AB;
    text-align: center;
    .banner-bg{
      position:absolute;
      left:50px;
      height:90%;
      bottom:0px;
    }
    .banner-bg-mobile{
      display: none;
    }
    .info-group{
      .main-title{
        font-size: 42px;
        color: #FFFFFF;
        letter-spacing: 4.2px;
      }
      .info-content{
        color:white;
       
      }
      .btn-box .btn-orange{
        margin-right:20px
      }
    }
    .banner-pg{
      vertical-align: top;
      height:341px;
    } 
    .banner-content{
      position:absolute;
      top:95px;
      left:50%;
      transform: translateX(-50%);
      display: inline-block;
      white-space: nowrap;
    }
  }
  .version-info{
    position:relative;
    height:618px;
    width:100%;
    background: white;
    .main-title{
      position:absolute;
      top:30px;
      left:7%;
    }
    .menu-group{
      position:absolute;
      top:130px;
      left:10%;
      width:208px;
      .menu-item{
        height:50px;
        line-height:50px;
        padding:0px 20px;
        text-align: left;
        letter-spacing: 1.6px;
        cursor: pointer;
        &:hover{
          color:#FD781D;
        }
        .iconfont{
          visibility: hidden;
          display: inline-block;
          transform: rotate(-90deg);
          margin-left:45px;
          opacity:0;
        }
        span{
          display: inline-block;
          -webkit-transition: all 400ms linear;
          -moz-transition: all 400ms linear;
          -ms-transition: all 400ms linear;
          -o-transition: all 400ms linear;
          transition: all 400ms linear;
        }
      }
      .menu-item.active{
        background: #FFFFFF;
        box-shadow: 0 2px 15px 0 rgba(0,0,0,0.10);
        border-radius: 8px;
        color:#FD781D;
        -webkit-transition: all 400ms linear;
        -moz-transition: all 400ms linear;
        -ms-transition: all 400ms linear;
        -o-transition: all 400ms linear;
        transition: all 400ms linear;
        span{
          transform: translateX(30px);
        }
        .iconfont{
          visibility: visible;
          opacity:1;
        }
      }
    }
    .main-png{
      position:absolute;
      right: 7%;
      bottom:0px;
      width:876px;
      visibility: hidden;
    }
    .main-png.active{
      visibility: visible;
    }
  } 
  .center-info{
    width:100%;
    padding:40px 0px;
    .info-container{
      position:relative;
      height:100%;
      width:80%;
      display: flex;
      justify-content: center;
      margin:0 auto;
      .info-left{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        
      }
      .info-left,.info-right{
        width: 45%;
      }
      .info-right{
        display: flex;
        justify-content: center;
      }
      .bg{
        width: 85%;
        height: auto!important;
      }
      .info-group{
        right:initial;
        left:initial;
        min-width:300px;
        margin-right:0;
        ul{
          position:relative;
          margin-top:50px;
          list-style: none;
          padding-left:20px;
          >li{
            position:relative;
            margin:10px 0px;
            &:before{
              content:'';
              position:absolute;
              width:4px;
              height:4px;
              background: #FD781D;
              border-radius: 10px;
              left:-15px;
              top:8px;
            }
          }
        }
      }
    
    }
  } 
  .center-info.w-bg{
    background: white;
  }
  .center-info.data-center{
    .info-container{
      .info-group{
        width:380px;
      }
    }
  }
  .center-info.segment-center {
    .info-container{
      .bg{
        height:80%;
      }
      .info-group{
        width:402px;
      }
    }
  }
  .center-info.ana-center,.center-info.ai-center,.center-info.market-center {
    .info-container{
      .bg{
        height:80%;
      }
      .info-group{
        width:380px;
      }
    }
  }
  .center-info.l{
    .info-container{
      .info-left{
        left:0;
      }
      .info-right{
        right:0;
      }
    }
  }
  .center-info.r {
    .info-container{
      .info-left{
        right:0;
      }
      .info-right{
        left:0;
      }
    }
  }
  .cloud-info{
    position:relative;
    min-height:250px;
    background: white;
    padding:30px 50px 50px 50px;
    text-align: center;
    .row{
      margin-top:10px;
      >div{
        text-align: center;
      }
    }
    .cloud-detail{
      position:relative;
      display: inline-block;
      width:80%;
      min-width:520px;
      height:130px;
      background: #f9f9f9;
      display: flex;
      align-items: center;
      justify-content: center;
     
      .cloud-icon {
        display: inline-block;
        margin-right:15px;
        .icon{
          width:80px;
        }
        .text{
          font-size: 16px;
          color: #454545;
          letter-spacing: 1.6px;
        }
      }
      .cloud-desc{
        display: inline-block;
        left:200px;
        font-size: 14px;
        color: #454545;
        letter-spacing: 1.4px;
        text-align: center;
        max-width:300px;
      }
    }
  }

  .case-info{
    position:relative;
    padding:50px;
    text-align: center;
    .case-group{
      position:relative;
      height:200px;
      width:100%;
      margin-top:20px;
      text-align: center;
    } 
    .case-container{
      position:absolute;
      left:50%;
      transform: translateX(-50%);
      white-space: nowrap;
      .case-card{
        margin-right:18px;
        .case-card-icon{
          width: 84px;
        }
        .text{
          margin-top:10px;
        }
      }
    }
  }


  @media (max-width:1360px){
    .version-info .main-png{
        width:800px;
    }
    .center-info .info-container{
        width:100%;
    }
  }

  @media (max-width:1200px){
    .banner{
      .banner-pg{
        height:330px;
      } 
      .info-group{
        min-width:440px;
        margin-right:0;
      }
    }
    .version-info{
      .menu-group{
        position:absolute;
        top:130px;
        left:7%;
        width:175px;
      
        .menu-item.active{
          span{
            transform: translateX(15px);
          }
        }
      }
      .main-png{
        right: 5%;
        bottom:40px;
        width:700px;
      }
    } 
    .cloud-info{
      .cloud-detail{
        min-width: 430px;
      }
    }
   
  }
  @media (max-width:992px){
    .banner{
      .info-group{
        min-width:355px;
        margin-right:20px;
      }
      .banner-pg{
        height:260px;
        margin-top:20px;
      } 
    }
    .version-info{
      .menu-group{
        top:130px;
        left:5%;
        width:165px;
        .menu-item.active{
          span{
            transform: translateX(10px);
          }
        }
      }
      .main-png{
        right: 3%;
        bottom:95px;
        width:585px;
      }
    } 
    .cloud-info{
      .cloud-detail{
        width: 90%;
        margin:20px auto;
      }
    }
    .case-info{
     
      .case-container{
        left:50%;
        transform: translateX(-50%);
        .case-card{
          width: 235px;
          height: 150px;
          .case-card-icon{
            width: 60px;
          }
          .text{
            bottom:20px;
          }
        }
      }
    }
  }
}