.forum{
  background: #fff;
  overflow: hidden;
  .forum-news-list{
    display: flex;
    justify-content: center;
    background-color: rgb(248, 248, 248);
  }
  
  .news-list-wrapper{
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    width: 100vw;
    justify-content: space-evenly;
    .list-item{
      height: 296px;
      width: 344px;
      border-radius: 8px;
      background-color: #fff;
      position: relative;
      margin-bottom: 30px;
      .target{
        display: inline-block;
        height: 296px;
      }
      
      .description{
        padding: 16px;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: #000000D9;
      }
      .from{
        position: absolute;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        color: #00000073;
        left: 16px;
        bottom: 16px;
      }
      
    }
  }
  .qrcode{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(248, 248, 248);
    padding-top: 80px;
    padding-bottom: 270px;
    .qrcodeImgWrapper{
      display: flex;
      flex-direction: column;
      align-items: center;
      .view{
        padding-top: 16px;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
      }
    }
    .playback{
      height: 40px;
      width: 160px;
      border-radius: 20px;
      padding: 10px, 24px, 10px, 24px;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      background-color:#FF6800;
      color: #fff;
      margin-left: 40px;
      border:none;
      cursor: pointer;
    }
  }
}